import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { booleanPointInPolygon, point, polygon } from '@turf/turf';
import { uniq } from 'lodash';
import moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ShutterTypeEnum, TaskStateEnum } from '../../detailed-site/state/detailed-site.model';
import { isLocalCS } from '../../shared/utils/backend-services';
import { isDefined } from '../../shared/utils/general';
import { Cartographic } from '../../shared/utils/geo';
import { TenantQuery } from '../../tenant/tenant.query';
import { Step } from '../wizard-stepper/steps';
import {
  FileToUpload,
  GeorefMethodEnum,
  ImagePoint,
  ImageToApprove,
  MapHoveredNames,
  ROLLING_SHUTTER_FLIGHT_SPEED_THRESHOLD_M_PER_SEC,
  ROLLING_SHUTTER_HINT_ELECTRONIC,
  ROLLING_SHUTTER_HINT_HIGH_SPEED,
  ROLLING_SHUTTER_HINT_MECHANICAL,
  TotalValidationWarning,
  UploadWizardMode,
  ValidationError
} from './upload-wizard.model';
import { UploadWizardState, UploadWizardStore } from './upload-wizard.store';

export const MIN_IMAGES_PER_FLIGHT = 3;
export const MIN_NUM_ITEMS_FOR_ROLLING_SHUTTER_CALC = 50;

export const sortFilesWithExifToUpload = (f1: FileToUpload, f2: FileToUpload) => (moment(f2.exif.date).isAfter(f1.exif.date) ? -1 : 1);
export const sortAllFilesToUpload = (f1: FileToUpload, f2: FileToUpload) => {
  // First compare by date (if exif exists)
  if (f1.exif && f2.exif) {
    if (f1.exif.date && f2.exif.date && f1.exif.date !== f2.exif.date) {
      return moment(f2.exif.date).isAfter(f1.exif.date) ? -1 : 1;
    }
  }

  // Then by exif existance
  if (f1.exif || f2.exif) {
    return f1.exif ? 1 : -1;
  }

  // Then by file name
  const clearName1 = splitFileName(f1.name).clearName;
  const clearName2 = splitFileName(f2.name).clearName;
  return clearName1.localeCompare(clearName2);
};

export const sortAllImagesToApprove = (img1: ImageToApprove, img2: ImageToApprove) => {
  const name1 = img1.name;
  const name2 = img2.name;
  return name1.localeCompare(name2);
};

export const splitFileName = (name: string) => {
  if (!name) {
    return null;
  }

  const lastPeriodPosition = name.lastIndexOf('.');
  if (lastPeriodPosition < 0) {
    return { clearName: name, extension: '' };
  } else {
    return { clearName: name.slice(0, lastPeriodPosition), extension: name.slice(lastPeriodPosition + 1) };
  }
};

@Injectable({ providedIn: 'root' })
export class UploadWizardQuery extends Query<UploadWizardState> {
  uploadFilesQuery = new QueryEntity(this.store.uploadFiles);
  approveImagesQuery = new QueryEntity(this.store.approveImages);

  step$ = this.select(store => store.ui.step);
  taskId$ = this.select(store => store.taskId);
  task$ = this.select(store => store.task);
  isManualMarking$ = this.select(
    store => store.task && (store.task.georefMethod === GeorefMethodEnum.MANUAL || store.task.state === TaskStateEnum.FAILEDAUTOGEOREF)
  );
  isML$ = this.select(
    store => store.task?.georefMethod && [GeorefMethodEnum.MLGCP, GeorefMethodEnum.MLRTKGCP].includes(store.task.georefMethod)
  );
  automodel$ = this.select(store => store.ui.automodel);
  firstFlight$ = this.select(store => store.ui.isFirstFlight);
  boundingPolygon$ = this.select(store => store.boundingPolygon);
  hasAllValidRTKImages$ = this.select(store => store.hasAllValidRTKImages);
  uploadingLoading$ = this.select(store => store.uploadingLoading);
  uploadingError$ = this.select(store => store.ui.uploadingError);
  uploadResult$ = this.select(store => store.uploadResult);

  filesToUpload$: Observable<FileToUpload[]> = this.uploadFilesQuery.selectAll();
  imagesToApprove$: Observable<ImageToApprove[]> = this.approveImagesQuery.selectAll();

  activeImageName$: Observable<string> = this.select(store => store.activeImageName);
  cameraModels$: Observable<string[]> = this.filteredFiles$.pipe(
    map(filesToUpload =>
      uniq(
        filesToUpload
          .filter(file => file.exif)
          .map(file => file.exif.camModel)
          .filter(camModel => !!camModel)
      )
    )
  );
  countedTotalValidationErrors$: Observable<Partial<Record<ValidationError, number>>> = this.select(
    store => store.validation.countedTotalErrors
  );
  countedTotalValidationWarnings$: Observable<Partial<Record<TotalValidationWarning, number>>> = this.select(
    store => store.validation.countedTotalWarnings
  );
  mapHoveredNames$: Observable<MapHoveredNames> = this.select(store => store.mapHoveredNames);
  rollingShutterRecommendation$ = this.select(['avgFlightSpeed', 'shutterType']).pipe(
    map(({ avgFlightSpeed, shutterType }) => {
      let hint: string = null;
      let disable = false;
      let flagValue = false;

      const filteredItemsCount = this.getNumberOfFilteredItems();
      if (isDefined(shutterType) && isDefined(avgFlightSpeed) && filteredItemsCount >= MIN_NUM_ITEMS_FOR_ROLLING_SHUTTER_CALC) {
        if (shutterType === ShutterTypeEnum.ELECTRONIC) {
          flagValue = true;
          disable = true;
          hint = ROLLING_SHUTTER_HINT_ELECTRONIC;
        } else {
          const isSlowSpeed = avgFlightSpeed <= ROLLING_SHUTTER_FLIGHT_SPEED_THRESHOLD_M_PER_SEC;

          flagValue = !isSlowSpeed;
          if (shutterType === ShutterTypeEnum.MECHANICAL && isSlowSpeed) {
            disable = true;
            hint = ROLLING_SHUTTER_HINT_MECHANICAL;
          }

          if (!isSlowSpeed) {
            hint = ROLLING_SHUTTER_HINT_HIGH_SPEED;
          }
        }
      }

      return { hint, disable, flagValue };
    })
  );

  constructor(protected store: UploadWizardStore, private tenantQuery: TenantQuery) {
    super(store);
  }

  getCurrentStep() {
    return this.getValue().ui.step;
  }

  getAllFilesToUpload(): FileToUpload[] {
    return this.uploadFilesQuery.getAll();
  }

  getAllImagesToApprove(): ImageToApprove[] {
    return this.approveImagesQuery.getAll();
  }

  getAllImageFilePoints(): ImagePoint[] {
    return this.getAllFilesToUpload().map(file => this.getImagePointByName(file.name));
  }

  getAllImagePoints(): ImagePoint[] {
    return this.getAllImagesToApprove().map(image => this.getImagePointByName(image.name));
  }

  getImagePointByName(name: string): ImagePoint {
    if (!name) {
      return null;
    }
    const mode = this.getMode();
    if (mode === UploadWizardMode.CREATE) {
      const file = this.getFileToUploadByName(name);
      const location = file?.exif?.location;
      if (location?.longitude && location?.latitude) {
        return { name, longitude: location.longitude, latitude: location.latitude, warnings: file.warnings, errors: file.errors };
      }
    } else {
      const image = this.getImageToApproveByName(name);
      if (image?.longitude && image?.latitude) {
        return { name, longitude: image.longitude, latitude: image.latitude, warnings: image.warnings };
      }
    }
  }

  getFilesWithExifToUpload(): FileToUpload[] {
    return this.uploadFilesQuery.getAll().filter(file => file.exif);
  }

  getFilteredFilesToUpload(): FileToUpload[] {
    const allFiles = this.getFilesWithExifToUpload();
    const boundingPolygon = this.getValue().boundingPolygon;
    if (!boundingPolygon) {
      return allFiles;
    }
    return allFiles.filter(file => {
      const longitude = file?.exif?.location?.longitude;
      const latitude = file?.exif?.location?.latitude;
      return !!longitude && !!latitude && this.isPositionInsidePolygon({ longitude, latitude }, boundingPolygon);
    });
  }

  getFilteredImagesToApprove(): ImageToApprove[] {
    const allImages = this.getAllImagesToApprove();
    const boundingPolygon = this.getValue().boundingPolygon;
    if (!boundingPolygon) {
      return allImages;
    }
    return allImages.filter(image => {
      const longitude = image.longitude;
      const latitude = image.latitude;
      return !!longitude && !!latitude && this.isPositionInsidePolygon({ longitude, latitude }, boundingPolygon);
    });
  }

  get filteredFiles$() {
    return combineLatest([this.boundingPolygon$, this.filesToUpload$]).pipe(
      filter(([boundingPolygon, files]) => !!boundingPolygon && !!files),
      map(([boundingPolygon, files]) =>
        files.filter(file => {
          const longitude = file?.exif?.location?.longitude;
          const latitude = file?.exif?.location?.latitude;
          return !!longitude && !!latitude && this.isPositionInsidePolygon({ longitude, latitude }, boundingPolygon);
        })
      )
    );
  }

  get filteredImages$() {
    return combineLatest([this.boundingPolygon$, this.imagesToApprove$]).pipe(
      filter(([boundingPolygon, images]) => !!boundingPolygon && !!images),
      map(([boundingPolygon, images]) =>
        images.filter(image => {
          const longitude = image?.longitude;
          const latitude = image?.latitude;
          return !!longitude && !!latitude && this.isPositionInsidePolygon({ longitude, latitude }, boundingPolygon);
        })
      )
    );
  }

  private isPositionInsidePolygon = (position: Cartographic, polygonPositions: Cartographic[]) => {
    const pt = point([position.longitude, position.latitude]);
    const boundingPolygon = polygonPositions.map(pos => [pos.longitude, pos.latitude]);
    const poly = polygon([[...boundingPolygon, boundingPolygon[0]]]); // first and and last should be the same

    return booleanPointInPolygon(pt, poly);
  };

  getIsUploadingError() {
    return this.getValue().ui.uploadingError;
  }

  isValidNumberOfImages() {
    const imagesNumber = this.getNumberOfFilteredItems();
    return imagesNumber >= MIN_IMAGES_PER_FLIGHT;
  }

  hasValidationErrors() {
    return this.getTotalValidationErrors()?.length > 0;
  }

  hasValidationErrorsDisallowToContinue() {
    return this.getTotalValidationErrors()?.length > 0;
  }

  isLessThanMaxImages() {
    const filesToUpload = this.getFilteredFilesToUpload();
    const maxImagesPerFlight = this.tenantQuery.getMaxImagesPerFlight();
    return filesToUpload?.length <= maxImagesPerFlight;
  }

  isLessThanRemainingImages() {
    const filesToUpload = this.getFilteredFilesToUpload();
    const remainingImages = this.tenantQuery.getTotalRemainingImages();
    return filesToUpload?.length <= remainingImages;
  }

  hasValidationWarnings() {
    return this.getTotalValidationWarnings()?.length > 0;
  }

  getCountedTotalWarnings() {
    return this.getValue().validation.countedTotalWarnings;
  }

  getValidationLimits() {
    return this.getValue().validation.limits;
  }

  getTotalValidationErrors() {
    return Object.keys(this.getValue().validation.countedTotalErrors) as ValidationError[];
  }

  getTotalValidationWarnings() {
    return Object.keys(this.getValue().validation.countedTotalWarnings) as TotalValidationWarning[];
  }

  getValidationIssuesCount() {
    return this.getTotalValidationWarnings()?.length + this.getTotalValidationErrors()?.length;
  }

  getNumberOfCurrentItems(): number {
    const mode = this.getMode();
    return mode === UploadWizardMode.CREATE ? this.getFilesWithExifToUpload()?.length : this.approveImagesQuery.getCount();
  }

  getNumberOfFilteredItems(): number {
    const mode = this.getMode();
    return mode === UploadWizardMode.CREATE ? this.getFilteredFilesToUpload()?.length : this.getFilteredImagesToApprove()?.length;
  }

  getFileToUploadByName(name: string): FileToUpload {
    return this.uploadFilesQuery.getEntity(name);
  }

  getImageToApproveByName(name: string): ImageToApprove {
    return this.approveImagesQuery.getEntity(name);
  }

  getPrevImageToApproveByName(name: string): ImageToApprove {
    const allImages = this.approveImagesQuery.getAll().sort((img1, img2) => img1.name.localeCompare(img2.name));
    const imageIndex = allImages.findIndex(image => image.name === name);
    return imageIndex !== 0 ? allImages[imageIndex - 1] : null;
  }

  getAvgFlightSpeedAndShutterType() {
    const { avgFlightSpeed, shutterType } = this.getValue();
    return { avgFlightSpeed, shutterType };
  }

  getActiveName() {
    return this.getValue().activeImageName;
  }

  getHoveredImageName() {
    return this.getValue().mapHoveredNames.image;
  }

  getHoveredWarningName() {
    return this.getValue().mapHoveredNames.issue;
  }

  getHoveredNonCoverageAreaWarningName() {
    return this.getValue().mapHoveredNames.noncoverageWarning;
  }

  getNoncoverageWarningPositionByName(name: string) {
    const mode = this.getMode();
    const item = mode === UploadWizardMode.CREATE ? this.getFileToUploadByName(name) : this.getImageToApproveByName(name);
    const pointDeg = item?.nearestNoncoverageAreaCenter;
    return !!pointDeg && !!pointDeg.longitude && !!pointDeg.latitude
      ? Cesium.Cartesian3.fromDegrees(pointDeg.longitude, pointDeg.latitude)
      : null;
  }

  isStepEnabled(step: Step, mode: UploadWizardMode) {
    const state = this.getValue();
    const currentStep = state.ui.step;
    switch (currentStep) {
      case Step.IMAGES: {
        if (step === Step.FLIGHT_INFO && this.isValidNumberOfImages() && this.isLessThanMaxImages() && this.isLessThanRemainingImages()) {
          return true;
        }
        break;
      }

      case Step.FLIGHT_INFO:
        if (step === Step.IMAGES && mode !== UploadWizardMode.EDIT) {
          return true;
        }
        break;

      case Step.PREPROCESSING:
      case Step.GCP:
      case Step.MODEL_GENERATION:
        break;

      default:
        break;
    }

    return false;
  }

  getSite() {
    return this.getValue().site;
  }

  getSiteId() {
    return this.getValue().site?.id;
  }

  getTask() {
    return this.getValue().task;
  }

  getTaskId() {
    return this.getValue().task?.id;
  }

  getTaskState() {
    return this.getValue().task?.state;
  }

  getIsFrombackup() {
    return this.getValue().ui.isFromBackup;
  }

  getMode() {
    return this.getValue().ui.mode;
  }

  getHasAllValidRTKImages() {
    return this.getValue().hasAllValidRTKImages;
  }

  getIsAutomodel() {
    return this.getValue().ui.automodel;
  }

  getIsML() {
    const task = this.getTask();
    return task?.georefMethod && [GeorefMethodEnum.MLGCP, GeorefMethodEnum.MLRTKGCP].includes(task.georefMethod);
  }

  getIsRTK() {
    const task = this.getTask();
    return task?.rtk;
  }

  getIsManualMarking() {
    const task = this.getTask();
    return task && (task.georefMethod === GeorefMethodEnum.MANUAL || task.state === TaskStateEnum.FAILEDAUTOGEOREF);
  }

  getIsLocalCS() {
    const site = this.getSite();
    return isLocalCS(site?.coordinateSystem);
  }

  getUploadingLoading() {
    return this.getValue().uploadingLoading;
  }

  getIsFirstFlight() {
    return this.getValue().ui.isFirstFlight;
  }
}
