/**
 * Datumate Integration Service
 * API's for integration service
 *
 * OpenAPI spec version: 1.6.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DesignErrorModel } from './designErrorModel';

export interface GetFileResponse {
  activeDesignId?: string;
  current?: boolean;
  description?: string;
  designId?: string;
  designType?: GetFileResponse.DesignTypeEnum;
  errors?: Array<DesignErrorModel>;
  externalId?: string;
  id?: string;
  itemSize?: number;
  markedPrivate?: boolean;
  name?: string;
  path?: string;
  state?: GetFileResponse.StateEnum;
  sync?: boolean;
}
export namespace GetFileResponse {
  export type DesignTypeEnum = 'CAD' | 'SURFACE';
  export const DesignTypeEnum = {
    CAD: 'CAD' as DesignTypeEnum,
    SURFACE: 'SURFACE' as DesignTypeEnum
  };
  export type StateEnum = 'NOT_LOADED' | 'UPLOADING' | 'SYNCING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED' | 'DELETING';
  export const StateEnum = {
    NOTLOADED: 'NOT_LOADED' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    SYNCING: 'SYNCING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum,
    DELETING: 'DELETING' as StateEnum
  };
}
