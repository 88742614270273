import { Injectable } from '@angular/core';
import { Feature, multiPoint, MultiPoint, Position } from '@turf/turf';
import { BaseSurface } from '../../../../generated/mms/model/baseSurface';
import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import {
  BaseSurfaceSamplesHeights,
  GeoJsonProperties,
  PolygonWithSamplesCalcService,
  PrecalcData,
  TerrainSamplesValues
} from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import {
  BaseSurfaceType,
  CalcStatus,
  MapEntity,
  TaskOrDesignInfo,
  TaskOrDesignValues
} from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';

@Injectable({
  providedIn: 'root'
})
export class VolumeCalcService extends PolygonWithSamplesCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling, siteService);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    tasksAndDesignsInfo: TaskOrDesignInfo[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: TaskOrDesignValues[]; samplePoints: Feature<MultiPoint, GeoJsonProperties>; calcStatus: CalcStatus }> {
    const { horizontalArea, pointGridFeatureCollection, cellSide, isBboxSamplePointsInsidePolygon, bboxPointsCountDimentions } =
      precalcData;

    const geojsonProperties = entity?.geoJson?.features?.[0]?.properties as GeoJsonProperties;
    let terrainSamplesValues: TerrainSamplesValues[] = geojsonProperties?.terrainSamplesValues ?? [];
    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    if (!calcResult || calcResult.length === 0) {
      terrainSamplesValues = [];
    }
    let surfaceSamplesHeights: BaseSurfaceSamplesHeights[] = geojsonProperties?.surfaceSamplesHeights ?? [];

    for (let i = 0; i < tasksAndDesignsInfo.length; i++) {
      const taskOrDesignInfoForBaseSurfaceCalc: TaskOrDesignInfo =
        entity.baseSurface?.type === BaseSurfaceType.INTERPOLATED
          ? tasksAndDesignsInfo[i]
          : {
              id: this.siteQuery.getActiveTaskId(),
              type: 'TASK'
            };
      const { baseSurfaceSamplesHeights, isNewBaseSurfaceSamplesHeights } = await this.getBaseSurfaceSamplesHeights(
        siteId,
        taskOrDesignInfoForBaseSurfaceCalc,
        entity,
        pointGridFeatureCollection,
        surfaceSamplesHeights
      );
      if (
        ![BaseSurfaceType.MINELEVATION, BaseSurfaceType.CUSTOMELEVATION].includes(entity.baseSurface.type) &&
        !isDefined(baseSurfaceSamplesHeights?.heights)
      ) {
        return {
          calcResult: null,
          samplePoints: null,
          calcStatus: CalcStatus.SURFACE_SAMPLING_ERROR
        };
      }
      if (isNewBaseSurfaceSamplesHeights) {
        surfaceSamplesHeights = [...surfaceSamplesHeights, baseSurfaceSamplesHeights];
      }

      const { modelSamplesHeights, isNewModelSamplesHeights } = await this.getModelSamplesHeights(
        siteId,
        tasksAndDesignsInfo[i],
        entity.sourceModel,
        pointGridFeatureCollection,
        surfaceSamplesHeights
      );
      if (!isDefined(modelSamplesHeights?.heights)) {
        return {
          calcResult: null,
          samplePoints: null,
          calcStatus: CalcStatus.MODEL_SAMPLING_ERROR
        };
      }
      if (isNewModelSamplesHeights) {
        surfaceSamplesHeights = [...surfaceSamplesHeights, modelSamplesHeights];
      }

      if (baseSurfaceSamplesHeights?.heights.every(h => !isDefined(h))) {
        return {
          calcResult: null,
          samplePoints: null,
          calcStatus: CalcStatus.SURFACE_BOUNDS_ERROR
        };
      }

      const isBaseSurfaceIsCurrentTask =
        entity.baseSurface?.type === modelSamplesHeights?.type && baseSurfaceSamplesHeights?.id === modelSamplesHeights?.id;

      const volumeResults = this.getVolumesData(
        modelSamplesHeights.heights,
        baseSurfaceSamplesHeights?.heights,
        isBaseSurfaceIsCurrentTask,
        cellSide,
        horizontalArea,
        entity.baseSurface
      );

      terrainSamplesValues = [
        ...terrainSamplesValues,
        {
          id: tasksAndDesignsInfo[i].id,
          type: tasksAndDesignsInfo[i].type,
          samplesValues: volumeResults.volumeValues
        }
      ];

      calcResult = [
        ...calcResult,
        {
          id: tasksAndDesignsInfo[i].id,
          type: tasksAndDesignsInfo[i].type,
          values: [
            { ...FIELD_MAPPING.cutHorizontalArea, value: volumeResults.cutHorizontalArea },
            { ...FIELD_MAPPING.fillHorizontalArea, value: volumeResults.fillHorizontalArea },
            { ...FIELD_MAPPING.horizontalArea, value: horizontalArea },
            { ...FIELD_MAPPING.cut, value: volumeResults.cut },
            { ...FIELD_MAPPING.fill, value: volumeResults.fill },
            { ...FIELD_MAPPING.net, value: volumeResults.net },
            { ...FIELD_MAPPING.elevationMin, value: volumeResults.elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: volumeResults.elevationMax }
          ]
        }
      ];
    }

    const samplePointsProps: GeoJsonProperties = {
      ...bboxPointsCountDimentions,
      cellSide,
      isBboxSamplePointsInsidePolygon,
      terrainSamplesValues,
      surfaceSamplesHeights
    };

    const samplePositions: Position[] = pointGridFeatureCollection.features.map(f => f.geometry.coordinates);

    return {
      calcResult,
      samplePoints: multiPoint(samplePositions, samplePointsProps),
      calcStatus: CalcStatus.SUCCESS
    };
  }

  private getVolumesData(
    modelSamplesHeights: number[],
    baseSurfaceSamplesHeights: number[],
    isBaseSurfaceIsCurrentTask: boolean,
    cellSide: number,
    horizontalArea: number,
    baseSurface: BaseSurface
  ) {
    let belowSurfaceVolume = 0;
    let belowSurfaceHorizontalArea = 0;
    let aboveSurfaceVolume = 0;
    let aboveSurfaceHorizontalArea = 0;
    let noDiffHorizontalArea = 0;
    let elevationMin = Number.MAX_SAFE_INTEGER;
    let elevationMax = Number.MIN_SAFE_INTEGER;
    let baseSurfaceHeight = baseSurface.elevation;
    const pointsCount = modelSamplesHeights.length;
    const volumeValues: number[] = new Array(pointsCount).fill(null);

    const getDeltaVolume = (a: number, b: number) => {
      const diff = a - b;
      const deltaVolume = cellSide * cellSide * diff;
      return roundTo(deltaVolume, 6);
    };

    const updateElevationMin = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] < elevationMin) {
        elevationMin = modelHeights[index];
      }
    };

    const updateElevationMax = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] > elevationMax) {
        elevationMax = modelHeights[index];
      }
    };

    const updateVolumesWithBSTerrainHeights = (modelHeights: number[], index: number, baseSurfaceHeights: number[]) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeights[index])) {
        volumeValues[index] = 0;
        return;
      }

      const volumeDiff = getDeltaVolume(modelHeights[index], baseSurfaceHeights[index]);
      volumeValues[index] = volumeDiff;
      if (volumeDiff > 0) {
        aboveSurfaceVolume += volumeDiff;
        aboveSurfaceHorizontalArea += cellSide ** 2;
      } else if (volumeDiff < 0) {
        belowSurfaceVolume += volumeDiff;
        belowSurfaceHorizontalArea += cellSide ** 2;
      } else {
        noDiffHorizontalArea += cellSide ** 2;
      }
    };

    const updateVolumesWithBSHeight = (modelHeights: number[], index: number, baseSurfaceHeight: number) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeight)) {
        volumeValues[index] = 0;
        return;
      }

      const volumeDiff = getDeltaVolume(modelHeights[index], baseSurfaceHeight);
      volumeValues[index] = volumeDiff;
      if (volumeDiff > 0) {
        aboveSurfaceVolume += volumeDiff;
        aboveSurfaceHorizontalArea += cellSide ** 2;
      } else if (volumeDiff < 0) {
        belowSurfaceVolume += volumeDiff;
        belowSurfaceHorizontalArea += cellSide ** 2;
      } else {
        noDiffHorizontalArea += cellSide ** 2;
      }
    };

    const iterateSamples = (func: Function) => {
      for (let i = 0; i < pointsCount; i++) {
        func(i);
      }
    };

    let actionsList: Function[];
    const updateResData = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceSamplesHeights));
    const updateResDataWithBSHeight = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceHeight));

    if (isBaseSurfaceIsCurrentTask) {
      // Don't calculate map viz data when base surface is same as terrain
      actionsList = [updateElevationMin, updateElevationMax];
      iterateSamples(updateResData);
    } else {
      switch (baseSurface?.type) {
        case BaseSurfaceType.INTERPOLATED:
        case BaseSurfaceType.TASK:
        case BaseSurfaceType.DESIGN: {
          actionsList = [updateElevationMin, updateElevationMax, updateVolumesWithBSTerrainHeights];
          iterateSamples(updateResData);
          break;
        }
        case BaseSurfaceType.MINELEVATION: {
          actionsList = [updateElevationMin, updateElevationMax];
          iterateSamples(updateResData);
          // calc volumes after min elevation was calc
          baseSurfaceHeight = elevationMin;
          actionsList = [updateVolumesWithBSHeight];
          iterateSamples(updateResDataWithBSHeight);
          break;
        }
        case BaseSurfaceType.CUSTOMELEVATION: {
          actionsList = [updateElevationMin, updateElevationMax, updateVolumesWithBSHeight];
          iterateSamples(updateResDataWithBSHeight);
          break;
        }
      }
    }

    /**
     * Base Surface (baseSurface.isTarget = false):
     * above:     Fill
     *       ---------------------
     * below:      Cut
     */

    /**
     * Target Surface (baseSurface.isTarget = true):
     * above:    Cut
     *       ---------------------
     * below:    Fill
     */

    const cut = baseSurface.isTarget ? Math.abs(aboveSurfaceVolume) : Math.abs(belowSurfaceVolume);
    const fill = baseSurface.isTarget ? Math.abs(belowSurfaceVolume) : Math.abs(aboveSurfaceVolume);

    let cutHorizontalArea = baseSurface.isTarget ? aboveSurfaceHorizontalArea : belowSurfaceHorizontalArea;
    let fillHorizontalArea = baseSurface.isTarget ? belowSurfaceHorizontalArea : aboveSurfaceHorizontalArea;

    // Make total horizontal area equal to sum of 3 areas (cut, fill, no diff)
    const horizontalAreasSum = cutHorizontalArea + fillHorizontalArea + noDiffHorizontalArea;
    if (horizontalAreasSum !== horizontalArea) {
      const cutHorizontalAreaRatio = cutHorizontalArea / horizontalAreasSum;
      const fillHorizontalAreaRatio = fillHorizontalArea / horizontalAreasSum;

      const areaDiff = horizontalArea - horizontalAreasSum;

      cutHorizontalArea += areaDiff * cutHorizontalAreaRatio;
      fillHorizontalArea += areaDiff * fillHorizontalAreaRatio;
    }

    return {
      volumeValues,
      cut,
      fill,
      net: fill - cut,
      cutHorizontalArea,
      fillHorizontalArea,
      elevationMin: elevationMin === Number.MAX_SAFE_INTEGER ? 0 : elevationMin,
      elevationMax: elevationMax === Number.MIN_SAFE_INTEGER ? 0 : elevationMax
    };
  }
}
