import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, Store, StoreConfig, isDefined } from '@datorama/akita';
import { produce } from 'immer';
import { CloudFrontPreSignedPolicy } from '../../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import {
  Design,
  DesignActiveLayer,
  DesignCategory,
  DesignLayerProperty,
  DesignType,
  DesignsUploadingState,
  IntegrationDesignNode,
  RoadDesign
} from './detailed-site-designs.model';

export interface DetailedSiteDesignsState {
  siteId: string;
  viewerUrlsCredentials?: CloudFrontPreSignedPolicy;
  designsUploading: DesignsUploadingState;
  activeDesignLayer?: DesignActiveLayer;
}

export interface DesignState extends EntityState<Design>, MultiActiveState {}

export interface RoadDesignState extends EntityState<RoadDesign>, MultiActiveState {}

export interface DesignCategoryState extends EntityState<DesignCategory> {}

export interface IntegrationDesignState extends EntityState<IntegrationDesignNode>, MultiActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-designs-uploading' })
export class DetailedSiteDesignsStore extends Store<DetailedSiteDesignsState> {
  designs = new EntityStore<DesignState>({}, { name: 'site-designs' });
  roadDesigns = new EntityStore<RoadDesignState>({}, { name: 'site-road-designs' });
  designCategories = new EntityStore<DesignCategoryState>({}, { name: 'site-designs-categories' });
  integrationDesignNodes = new EntityStore<IntegrationDesignState>({ active: [] }, { name: 'integration-design-nodes', idKey: 'id' });

  constructor() {
    super({
      siteId: null,
      viewerUrlsCredentials: null,
      designsUploading: null,
      activeDesignLayer: { designId: null, layerId: null, layerName: null, featureIds: null, properties: null }
    });
  }

  initStore(siteId: string, viewerCredentials: CloudFrontPreSignedPolicy) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.siteId = siteId;
        draftState.viewerUrlsCredentials = viewerCredentials;
      })
    );
  }

  setActiveDesignLayerProperties(properties: DesignLayerProperty[]) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.activeDesignLayer.properties = properties;
      })
    );
  }

  setDesignActiveLayer(designId: string, layerId?: string, childLayers?: string[], layerName?: string) {
    const featureIds = isDefined(childLayers) ? new Set(childLayers) : null;
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.activeDesignLayer = { ...draftState.activeDesignLayer, designId, layerId, featureIds, layerName };
      })
    );
  }

  upsertDesigns(designs: Partial<Design>[]) {
    this.designs.upsertMany(designs.map(d => ({ ...d, type: DesignType.DESIGN })));
  }

  upsertRoadDesigns(roadDesigns: Partial<RoadDesign>[]) {
    this.roadDesigns.upsertMany(roadDesigns.map(d => ({ ...d, type: DesignType.ROAD_DESIGN })));
  }

  addDesign(design: Design) {
    this.designs.add(design);
  }

  updateDesign(designId: string, design: Partial<Design>) {
    this.designs.update(designId, design);
  }

  updateRoadDesign(roadDesignId: string, design: Partial<RoadDesign>) {
    this.roadDesigns.update(roadDesignId, design);
  }

  deleteDesign(designId: string) {
    this.designs.remove(designId);
  }

  deleteRoadDesign(roadDesignId: string) {
    this.roadDesigns.remove(roadDesignId);
  }

  setDesignCategories(categories: DesignCategory[]) {
    this.designCategories.upsertMany(categories);
  }

  addDesignCategory(category: DesignCategory) {
    this.designCategories.add(category);
  }

  updateDesignCategory(category: DesignCategory) {
    this.designCategories.update(category.id, category);
  }

  setDesignLayers(designId: string, type: DesignType, show: boolean, selectedLayersIds?: Set<string>) {
    const updateDesignLayers = (design: Design | RoadDesign) => {
      if (!isDefined(design?.layers)) {
        return design;
      }

      let allIsShown = true;
      let updatedLayers = design.layers.map(layer => {
        const showLayer = selectedLayersIds?.has(layer.id) || show;
        if (!showLayer && !layer.expandable) {
          allIsShown = false;
        }
        return {
          ...layer,
          show: !layer.expandable ? showLayer : false
        };
      });

      return {
        ...design,
        layers: updatedLayers,
        allIsShown
      };
    };

    if (type === DesignType.DESIGN) {
      this.designs.update(designId, updateDesignLayers);
    } else {
      this.roadDesigns.update(designId, updateDesignLayers);
    }
  }

  setDesignsUploadingState(designsUploading: Partial<DesignsUploadingState>) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        if (designsUploading) {
          draftState.designsUploading = {
            totalFiles: (designsUploading.totalFiles || draftState.designsUploading?.totalFiles) ?? 0,
            uploadedFiles: (designsUploading.uploadedFiles || draftState.designsUploading?.uploadedFiles) ?? 0,
            totalSize: (designsUploading.totalSize || draftState.designsUploading?.totalSize) ?? 0,
            uploadedSize: (designsUploading.uploadedSize || draftState.designsUploading?.uploadedSize) ?? 0,
            validating: designsUploading.validating
          };
        } else {
          draftState.designsUploading = null;
        }
      })
    );
  }

  reset() {
    super.reset();
    this.designs.reset();
    this.roadDesigns.reset();
    this.designCategories.reset();
    this.integrationDesignNodes.reset();
  }

  setActiveDesigns(activeDesigns: string[], designType: DesignType) {
    designType === DesignType.DESIGN ? this.designs.setActive(activeDesigns) : this.roadDesigns.setActive(activeDesigns);
  }

  toggleActiveDesign(designId: string, designType: DesignType) {
    designType === DesignType.DESIGN ? this.designs.toggleActive(designId) : this.roadDesigns.toggleActive(designId);
  }

  upsertIntegrationDesignNodes(nodes: IntegrationDesignNode[]) {
    this.integrationDesignNodes.upsertMany(nodes);
  }

  updateIntegrationDesignNode(node: Partial<IntegrationDesignNode>) {
    this.integrationDesignNodes.update(node.id, node);
  }
}
