import { Injectable } from '@angular/core';
import { EntityDirtyCheckPlugin, Query, QueryEntity } from '@datorama/akita';
import { combineLatest, map } from 'rxjs';
import { isDefined } from '../../../shared/utils/general';
import { generateViewerCredentialsQueryParams } from '../detailed-site.utils';
import { Design, DesignState, DesignType, IntegrationDesignNode, RoadDesign, RoadDesignType } from './detailed-site-designs.model';
import { DetailedSiteDesignsState, DetailedSiteDesignsStore } from './detailed-site-designs.store';

const integrationDesignNodeComparator = (head: IntegrationDesignNode, current: IntegrationDesignNode) => {
  return current.sync !== head.originalSync || current.designType !== head.originalDesignType;
};
@Injectable({ providedIn: 'root' })
export class DetailedSiteDesignsQuery extends Query<DetailedSiteDesignsState> {
  designsUploadingState$ = this.select(state => state.designsUploading);
  designsQuery = new QueryEntity(this.store.designs);
  designs$ = this.designsQuery.selectAll();
  readyDesigns$ = this.designsQuery.selectAll({ filterBy: design => design.jsonReady || design.cesium3DReady });

  roadDesignsQuery = new QueryEntity(this.store.roadDesigns);
  roadDesigns$ = this.roadDesignsQuery.selectAll();
  readyRoadDesigns$ = this.roadDesignsQuery.selectAll({ filterBy: roadDesign => roadDesign.jsonReady });

  designCategoriesQuery = new QueryEntity(this.store.designCategories, { sortBy: 'name' });
  designCategories$ = this.designCategoriesQuery.selectAll();

  activeDesigns$ = combineLatest([
    this.designsQuery.select(state => state.active),
    this.roadDesignsQuery.select(state => state.active)
  ]).pipe(
    map(([activeDesigns, activeRoadDesigns]) => {
      return {
        activeDesigns,
        activeRoadDesigns
      };
    })
  );

  activeDesignLayer$ = this.select(state => state.activeDesignLayer);

  integrationDesignQuery = new QueryEntity(this.store.integrationDesignNodes);
  integrationDesignSyncNodes$ = this.integrationDesignQuery.selectAll({ filterBy: node => node.sync });
  activeIntegrationDesignNodes$ = this.integrationDesignQuery.selectActive();

  constructor(protected store: DetailedSiteDesignsStore) {
    super(store);
  }

  getSiteId() {
    return this.getValue().siteId;
  }

  getViewerCredentials() {
    return this.getValue().viewerUrlsCredentials;
  }

  getAllDesigns() {
    return this.designsQuery.getAll();
  }

  getAllRoadDesigns() {
    return this.roadDesignsQuery.getAll();
  }

  getReadyRoadDesigns() {
    return this.roadDesignsQuery.getAll({ filterBy: roadDesign => roadDesign.state === DesignState.READY });
  }

  getAllDesignTypesCount() {
    return this.designsQuery.getCount() + this.roadDesignsQuery.getCount();
  }

  getDesignByType(designType: DesignType, designId: string) {
    return this.selectQueryByDesignType(designType).getEntity(designId);
  }

  getDesign(designId: string) {
    return this.designsQuery.getEntity(designId);
  }

  getDesignBySharedVersionId(sharedVersionId: string) {
    return this.getAllDesigns().find(design => design.sharedVersionId === sharedVersionId);
  }

  getRoadDesign(roadDesignId: string) {
    return this.roadDesignsQuery.getEntity(roadDesignId);
  }

  getRoadDesignBySharedVersionId(sharedVersionId: string) {
    return this.getAllRoadDesigns().find(design => design.sharedVersionId === sharedVersionId);
  }

  getRoadDesignsWithMesh() {
    return this.roadDesignsQuery.getAll({
      filterBy: roadDesign => roadDesign.roadDesignType === RoadDesignType.SURFACE && roadDesign.meshReady
    });
  }

  getDesignsWithTerrain() {
    return this.designsQuery.getAll({ filterBy: design => design.terrainReady });
  }

  getSurfaceDesignsWithTerrain() {
    return this.designsQuery.getAll({ filterBy: design => design.hasSurface && design.terrainReady });
  }

  getDesignUrls(designId: string) {
    const credentials = this.getViewerCredentials();
    const queryParameters = generateViewerCredentialsQueryParams(credentials);
    return {
      terrain: `${credentials.url}/designs/${designId}/terrain`,
      geojson: `${credentials.url}/designs/${designId}/design.json?${queryParameters}`,
      tiles: `${credentials.url}/designs/${designId}/tiles`,
      tiles2DMetadata: `${credentials.url}/designs/${designId}/tiles/metadata.json?${queryParameters}`,
      tiles3DMetadata: `${credentials.url}/designs/${designId}/3dTiles/tileset.json?${queryParameters}`
    };
  }

  getAllDesignCategories() {
    return this.designCategoriesQuery.getAll();
  }

  getDesignCategory(id: string) {
    return this.designCategoriesQuery.getEntity(id);
  }

  getFullyShownDesigns() {
    return [
      ...this.designsQuery.getAll({ filterBy: design => design.allIsShown }),
      ...this.roadDesignsQuery.getAll({ filterBy: roadDesign => roadDesign.allIsShown })
    ];
  }

  getShownLayers(): { designId: string; designType: DesignType; layerName: string }[] {
    const shownLayers: { designId: string; designType: DesignType; layerName: string }[] = [];

    [...this.getAllDesigns(), ...this.getAllRoadDesigns()]
      .filter(design => !!design && !design.allIsShown && !!design.layers)
      .forEach(design => {
        Object.entries(design.layers)
          .filter(([, isShown]) => isShown)
          .forEach(([layerName]) => shownLayers.push({ designId: design.id, designType: design.type, layerName }));
      });
    return shownLayers;
  }

  isDesignActive(id: string, type: DesignType) {
    return this.selectQueryByDesignType(type).hasActive(id);
  }

  getActiveDesigns(type: DesignType) {
    return this.selectQueryByDesignType(type).getActive();
  }

  isActiveDesign(design: Design | RoadDesign) {
    return this.selectQueryByDesignType(design.type).hasActive(design.id);
  }

  private selectQueryByDesignType(type: DesignType) {
    return type === DesignType.DESIGN ? this.designsQuery : this.roadDesignsQuery;
  }

  getActiveLayerFeaturesId() {
    return this.getValue().activeDesignLayer?.featureIds || null;
  }

  getActiveLayerId() {
    return this.getValue().activeDesignLayer?.layerId;
  }

  getActiveLayerProperties() {
    return this.getValue().activeDesignLayer?.properties;
  }

  getActiveLayerDesignId() {
    return this.getValue().activeDesignLayer?.designId;
  }

  isFeatureActive(featureId: string) {
    return this.getActiveLayerFeaturesId()?.has(featureId);
  }

  getAllIntegrationDesignNodes() {
    return this.integrationDesignQuery.getAll();
  }

  getIntegrationDesignNodesByIds(ids: string[]) {
    return isDefined(ids) ? ids.map(id => this.integrationDesignQuery.getEntity(id)) : [];
  }

  getIntegrationDesignNodesDirtyCheck() {
    const dirtyCheck = new EntityDirtyCheckPlugin(this.integrationDesignQuery, {
      entityIds: this.getAllIntegrationDesignNodes().map(node => node.id),
      comparator: integrationDesignNodeComparator
    });
    dirtyCheck.setHead();
    return dirtyCheck;
  }
}
