/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { startCase } from 'lodash';
import * as mixpanelModule from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { GetArtifactResponse } from '../../../generated/file/model/getArtifactResponse';
import { BaseSurface } from '../../../generated/mms/model/baseSurface';
import { ShareResourceRequest } from '../../../generated/tenant/model/shareResourceRequest';
import { LoginResponse } from '../../../generated/ums/model/loginResponse';
import { USER_ROLE_NAMES, UserRole } from '../../auth/state/auth.utils';
import { AssociateDesignData } from '../../detailed-site/activity-details-box/activity-associate-dialog/activity-associate-dialog.component';
import {
  DrawingStylePropName,
  isNewDrawing
} from '../../detailed-site/measurements-details-box/annotation-details-box-content/annotation-utils.service';
import { MultiEntityType } from '../../detailed-site/site-map/map-tools/measure-tools/measure-actions/measure-actions.component';
import { UploadMapping } from '../../detailed-site/site-timeline/import-model-dialog/import-model-dialog.component';
import {
  Activity,
  ActivityMeasurement,
  ActivityMeasurementType,
  ProjectPlan
} from '../../detailed-site/state/detailed-site-activities/detailed-site-activities.model';
import {
  CustomPropertyInteractionType,
  Design,
  DesignCustomProperty,
  DesignLayerProperty,
  DesignType,
  DesignVersion,
  IntegrationDesignNode,
  RoadDesign
} from '../../detailed-site/state/detailed-site-designs/detailed-site-designs.model';
import {
  Analytic,
  AnalyticType,
  Annotation,
  AnnotationFile,
  AnnotationType,
  BaseSurfaceType,
  Drawing,
  DrawingType,
  EntityType,
  EntityTypeWithBaseSurface,
  MapEntity,
  Measurement,
  MeasurementType,
  ModelEdit,
  ModelEditType
} from '../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import {
  GridReportType,
  ReportEntity,
  ReportType,
  WaterFlowReport
} from '../../detailed-site/state/detailed-site-reports/detailed-site-reports.model';
import { GeorefMethodEnum, MapTextureType, SurfaceReferenceType, Task } from '../../detailed-site/state/detailed-site.model';
import { DetailedSiteQuery } from '../../detailed-site/state/detailed-site.query';
import { DeltaElevationVizOptions, isNewEntity } from '../../detailed-site/state/detailed-site.utils';
import { OperatorSite } from '../../operator/state/operator.model';
import {
  CrossSectionProgressReportData,
  CrossSectionVolumeReportData
} from '../../reports/cross-section-report/cross-section-report.model';
import {
  RoadGradeCheckingReportData,
  SurfaceGradeCheckingReportData
} from '../../reports/grade-checking-report/grade-checking-report.model';
import { GridHeatmapReportData } from '../../reports/grid-heatmap-report/grid-heatmap-report.model';
import { ArtifactEnum, CoordinateSystem, IntegrationEnum, IntegrationProject, Site, User } from '../../tenant/tenant.model';
import { GCPItem, GCPType } from '../../upload-wizard/gcp-marking/state/gcp.model';
import { CoordinateSystemDialogOption } from '../coordinate-system-modifier/coordinate-system-modifier.component';
import { Announcement } from '../navbar/announcement-menu/state/announcements.store';
import { ResourceLinkType } from '../resource-links/resource-links.model';
import { MapStyle } from '../utils/cesium-common';
import { formatName } from '../utils/formatting';
import { isDefined } from '../utils/general';
import { Cartographic } from '../utils/geo';
import { LocaleService } from './locale.service';

const CREATED_SITES = 'Count Of Created Sites';
const FLIGHTS_GENERATIONS = 'Count Of Flight Generations';
const IMPORTED_MODELS = 'Count Of Imported Models';
const CREATED_MEASUREMENTS = 'Count Of Created Measurements';
const CREATED_ANALYTICS = 'Count Of Created Analytics';
const CREATED_ANNOTATIONS = 'Count Of Created Annotations';
const CREATED_MODEL_EDIT_AREAS = 'Count Of Created Model Edit Areas';
const REPORT_DOWNLOADS = 'Count Of Report Downloads';
const DXF_DOWNLOADS = 'Count Of Entity DXF Downloads';
const DESIGN_DOWNLOADS = 'Count Of Design Downloads';
const CSV_DOWNLOADS = 'Count Of CSV Downloads';
const IMPORTED_DESIGNS = 'Count Of Imported Designs';
const IMPORTED_ROAD_DESIGNS = 'Count Of Imported Road Designs';
const CONSOLE_LOG_STYLE = 'text-transform:uppercase;font-weight:bold; font-size: 12px; background:#ff8000; padding: 2px 8px;color: white;';

interface LoginUserData {
  isNew: boolean;
  activeSession: LoginResponse;
  email: string;
  deviceType: string;
  role: UserRole;
  loginBySSO?: boolean;
  providerName?: string;
}

interface ActivityData extends Activity {
  projectPlanId: string;
  version: string;
}

const writeToLog = (...messages: string[]) => {
  console.debug(...messages);
};

const stylizeLog = (eventName: string) => {
  return [`%c\u{1F4CA} Mixpanel ${eventName}:`, CONSOLE_LOG_STYLE];
};

// Don't use analytics in dev environment
const mixpanel =
  environment.production && environment.mixpanelToken
    ? mixpanelModule
    : new Proxy(mixpanelModule, {
        get: (target, key: string) => {
          if (key === 'people') {
            return {
              set: (props: any) => writeToLog(...stylizeLog('people.set'), props),
              set_once: (props: any) => writeToLog(...stylizeLog('people.set_once'), props),
              increment: (counterName: string) => writeToLog(...stylizeLog('people.increment'), counterName)
            };
          }

          const value = target[key];

          if (value instanceof Function) {
            return function (...args) {
              writeToLog(...stylizeLog(key as string), ...args);
            };
          }

          return value;
        }
      });

function execute(funcName: string, func: Function) {
  try {
    func();
  } catch (e) {
    console.error(`Mixpanel ${funcName} track error:`, e.message);
  }
}

function addPrefixToObjectKeys(o: object, prefix: string) {
  if (!isDefined(o)) {
    return o;
  }

  return Object.entries(o).reduce((result, [key, value]) => {
    result[prefix + key] = value;
    return result;
  }, {});
}

function handleEntityId(id: string) {
  return !id || isNewEntity(id) ? 'New' : id;
}

function getYesNo(value: boolean) {
  return value ? 'Yes' : 'No';
}

function convertDateIfNecessary(date: Date | string) {
  if (date) {
    if (typeof date === 'string') {
      return date;
    } else {
      return date.toISOString();
    }
  } else {
    return '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private siteQuery: DetailedSiteQuery, private localeService: LocaleService) {}

  static trackGeneralEvent(eventName: string, properties?: any) {
    execute(eventName, () => {
      mixpanel.track(eventName, properties);
    });
  }

  initialize() {
    this.initMixpanel();
    this.registerSessionProps();
  }

  private initMixpanel() {
    execute(this.initialize.name, () => {
      mixpanel.init(environment.mixpanelToken, { persistence: 'localStorage' });
    });
  }

  private registerSessionProps() {
    execute(this.registerSessionProps.name, () => {
      const sessionProps = {
        Locale: this.localeService.locale
      };
      mixpanel.register(sessionProps);
    });
  }

  login(userData: LoginUserData) {
    execute(this.login.name, () => {
      if (userData.role === UserRole.ACCOUNT_USER && !userData.activeSession) {
        return;
      }

      // Detect a user
      const userId = userData.role === UserRole.OPERATOR ? userData.email : userData.activeSession.userId;
      if (userData.isNew) {
        mixpanel.alias(userId);
      } else {
        mixpanel.identify(userId);
      }

      // Handle super properties. Will be added to each event
      const superProps = {
        'User Role': USER_ROLE_NAMES[userData.role],
        'Device Type': userData.deviceType
      };
      mixpanel.register(superProps);
      if (userData.role === UserRole.ACCOUNT_USER) {
        this.registerTenantSuperProps(userData.activeSession);
      }

      // Call a tracking after a super props registration to set these props to the login event
      mixpanel.track('Login', { 'Is Logged In By SSO': userData.loginBySSO, 'SSO Provider Name': userData.providerName });

      // Handle people properties.
      // 'set' overwrites values, used for props that can be changed only by the third part.
      // 'set_once' doesn't overwrite previous props, sets properties on a user record, only if they do not yet exist.
      const peopleProps = {
        $email: userData.email,
        'User Role': USER_ROLE_NAMES[userData.role],
        $last_seen: new Date().toISOString()
      };
      mixpanel.people.set(peopleProps);

      if (userData.role === UserRole.ACCOUNT_USER) {
        const accountUserPeopleProps = {
          $name: `${userData.activeSession.firstName} ${userData.activeSession.lastName}`,
          $first_name: userData.activeSession.firstName,
          $last_name: userData.activeSession.lastName,
          $phone: userData.activeSession.phone,
          'User Access Level': userData.activeSession.accessLevel
        };
        const accountUserNonRewritebleProps = {
          'Tenant ID': userData.activeSession.tenantId,
          'Tenant Name': userData.activeSession.tenantName,
          [CREATED_SITES]: 0,
          [FLIGHTS_GENERATIONS]: 0,
          [IMPORTED_MODELS]: 0,
          [CREATED_MEASUREMENTS]: 0,
          [CREATED_ANALYTICS]: 0,
          [CREATED_ANNOTATIONS]: 0,
          [CREATED_MODEL_EDIT_AREAS]: 0,
          [REPORT_DOWNLOADS]: 0,
          [DXF_DOWNLOADS]: 0,
          [CSV_DOWNLOADS]: 0,
          [IMPORTED_DESIGNS]: 0,
          [IMPORTED_ROAD_DESIGNS]: 0
        };
        mixpanel.people.set(accountUserPeopleProps);
        mixpanel.people.set_once(accountUserNonRewritebleProps);
      }
    });
  }

  tryToLoginBySSO(providerName: string) {
    execute(this.tryToLoginBySSO.name, () => {
      mixpanel.track('Try To Login By SSO', { 'Provider Name': providerName });
    });
  }

  logout(byInactivity = false) {
    execute(this.logout.name, () => {
      if (!byInactivity) {
        mixpanel.track('Logout');
      }

      // Generates a new random distinct_id and clears super properties.
      // Allows to handle multiple users on a single device
      mixpanel.reset();
    });
  }

  toggleOperatorSessionData(userSession: LoginResponse) {
    execute(this.toggleOperatorSessionData.name, () => {
      this.registerTenantSuperProps(userSession);

      if (!userSession) {
        this.toggleOperatorSite(null);
      }
    });
  }

  switchAccount(userSession: LoginResponse) {
    execute(this.switchAccount.name, () => {
      mixpanel.track('Switch Account', {
        'Tenant ID': userSession.tenantId,
        'Tenant Name': userSession.tenantName
      });

      this.registerTenantSuperProps(userSession);
    });
  }

  private registerTenantSuperProps(userSession: LoginResponse) {
    if (userSession) {
      const userSuperProps = {
        'User Name': `${userSession.firstName} ${userSession.lastName}`,
        'User Access Level': userSession.accessLevel,
        'Tenant ID': userSession.tenantId,
        'Tenant Name': userSession.tenantName
      };
      mixpanel.register(userSuperProps);
    } else {
      mixpanel.unregister('User Name');
      mixpanel.unregister('User Access Level');
      mixpanel.unregister('Tenant ID');
      mixpanel.unregister('Tenant Name');
    }
  }

  toggleOperatorSite(site: OperatorSite) {
    execute(this.toggleOperatorSite.name, () => {
      if (site) {
        mixpanel.register({
          'Site ID': site.id,
          'Site Name': site.name
        });
      } else {
        mixpanel.unregister('Site ID');
        mixpanel.unregister('Site Name');
      }
    });
  }

  enterToSite(siteId: string, siteName: string) {
    execute(this.enterToSite.name, () => {
      mixpanel.register({
        'Site ID': siteId,
        'Site Name': siteName
      });
      mixpanel.track('Enter To Site');
    });
  }

  exitFromSite() {
    execute(this.exitFromSite.name, () => {
      mixpanel.track('Exit From Site');
      mixpanel.unregister('Site ID');
      mixpanel.unregister('Site Name');
      mixpanel.unregister('Flight ID');
    });
  }

  addSite(site: Site) {
    execute(this.addSite.name, () => {
      mixpanel.people.increment(CREATED_SITES);
      mixpanel.track('Add Site', this.fetchSiteProperties(site));
    });
  }

  editSite(site: Site) {
    execute(this.editSite.name, () => mixpanel.track('Edit Site', this.fetchSiteProperties(site)));
  }

  updateSiteAssociation(site: Site, project: IntegrationProject) {
    execute(this.updateSiteAssociation.name, () =>
      mixpanel.track('Edit Site Association', {
        ...this.fetchSiteProperties(site),
        ...this.fetchAssociationProjectProperties(project)
      })
    );
  }

  removeSiteAssociation(site: Site, project: IntegrationProject, keepCurrentVersions: boolean) {
    execute(this.removeSiteAssociation.name, () =>
      mixpanel.track('Remove Site Association', {
        ...this.fetchSiteProperties(site),
        ...this.fetchAssociationProjectProperties(project),
        'Keep Synchronized Data': getYesNo(
          project.integration === IntegrationEnum.AUTODESK && project.artifact === ArtifactEnum.DESIGN ? keepCurrentVersions : true
        )
      })
    );
  }

  deleteSite(site: Site) {
    execute(this.deleteSite.name, () => mixpanel.track('Delete Site', this.fetchSiteProperties(site)));
  }

  uploadTaskImages(task: Task, fromBackup: boolean) {
    execute(this.uploadTaskImages.name, () =>
      mixpanel.track('Upload Flight Images', {
        ...this.fetchTaskProperties(task),
        'Images Source': fromBackup ? 'Backup' : 'Initial User Uploading'
      })
    );
  }

  uploadTaskImagesError(task: Task, error: string) {
    execute(this.uploadTaskImages.name, () =>
      mixpanel.track('Upload Flight Images Error', { ...this.fetchTaskProperties(task), 'Error Message': startCase(error) })
    );
  }

  approveTask(task: Task) {
    execute(this.approveTask.name, () => mixpanel.track('Approve Flight', this.fetchTaskProperties(task)));
  }

  reprocessTask(task: Task, newGeorefMethod: GeorefMethodEnum, rollingShutter: boolean, rtk: boolean) {
    execute(this.reprocessTask.name, () =>
      mixpanel.track('Reprocess Flight', {
        'New Flight Geo Referencing Method': newGeorefMethod,
        'New Flight With Rolling Shutter': getYesNo(rollingShutter),
        'New Flight With RTK': getYesNo(rtk),
        ...this.fetchTaskProperties(task)
      })
    );
  }

  generateTask(task: Task) {
    execute(this.generateTask.name, () => {
      mixpanel.people.increment(FLIGHTS_GENERATIONS);
      mixpanel.track('Generate Flight', this.fetchTaskProperties(task));
    });
  }

  deleteFlight(flight: Task) {
    execute(this.deleteFlight.name, () => mixpanel.track('Delete Flight', this.fetchTaskProperties(flight)));
  }

  importModel(importedModel: Task, uploadMapping: UploadMapping) {
    execute(this.importModel.name, () => {
      mixpanel.people.increment(IMPORTED_MODELS);
      mixpanel.track('Import model', {
        ...this.fetchTaskProperties(importedModel),
        'Ortho Files Count': uploadMapping.orthophoto.uploadedFiles,
        'Ortho Files Size': uploadMapping.orthophoto.uploadedSize,
        'Point Cloud Files Count': uploadMapping.pointcloud.uploadedFiles,
        'Point Cloud Files Size': uploadMapping.pointcloud.uploadedSize
      });
    });
  }

  enterToFlight(flightId: string, flightDateLabel: string) {
    execute(this.enterToFlight.name, () => {
      mixpanel.register({
        'Flight ID': flightId,
        'Flight Date': flightDateLabel
      });
      mixpanel.track('Enter To Flight');
    });
  }

  changeMapTextureType(mapTextureType: MapTextureType) {
    execute(this.changeMapTextureType.name, () => mixpanel.track('Change Map Texture Type', { 'Map Texture Type': mapTextureType }));
  }

  useDiffSliderTool(activeTask: Task, compareTask: Task) {
    execute(this.useDiffSliderTool.name, () => {
      mixpanel.track('Use Diff Slider Tool', {
        'Is Open': !!compareTask,
        'Site ID': activeTask.siteId,
        'Active Flight ID': activeTask.id,
        'Active Flight Mission Date': activeTask.flightDateLabel,
        'Compared Flight ID': compareTask?.id,
        'Compared Flight Mission Date': compareTask?.flightDateLabel
      });
    });
  }

  addUser(user: User, siteIds: string[], teamsIds: string[]) {
    execute(this.addUser.name, () => {
      mixpanel.track('User Added', {
        ...addPrefixToObjectKeys(this.fetchUserProperties(user), 'Created '),
        'Created User Site IDs': siteIds.join(', '),
        'Created User Team IDs': teamsIds.join(', ')
      });
    });
  }

  updateUser(user: User, siteIds: string[], teamsIds: string[]) {
    execute(this.updateUser.name, () => {
      mixpanel.track('User Updated', {
        ...addPrefixToObjectKeys(this.fetchUserProperties(user), 'Updated '),
        'Updated User Site IDs': siteIds.join(', '),
        'Updated User Team IDs': teamsIds.join(', ')
      });
    });
  }

  deleteUser(user: User) {
    execute(this.deleteUser.name, () => {
      mixpanel.track('User Deleted', {
        ...addPrefixToObjectKeys(this.fetchUserProperties(user), 'Deleted ')
      });
    });
  }

  addEntity(entity: MapEntity) {
    execute(this.addEntity.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.people.increment(CREATED_ANALYTICS);
        mixpanel.track('Add Analytic', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.people.increment(CREATED_MEASUREMENTS);
        mixpanel.track('Add Measurement', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.people.increment(CREATED_ANNOTATIONS);
        mixpanel.track('Add Annotation', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.people.increment(CREATED_MODEL_EDIT_AREAS);
        mixpanel.track('Add Model Edit Area', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  editEntity(entity: MapEntity) {
    execute(this.editEntity.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.track('Edit Analytic', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.track('Edit Measurement', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.track('Edit Annotation', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.track('Edit Model Edit Area', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  deleteEntity(entity: MapEntity) {
    execute(this.deleteEntity.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.track('Delete Analytic', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.track('Delete Measurement', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.track('Delete Annotation', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.track('Delete Model Edit Area', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  setActiveEntity(entity: MapEntity) {
    if (isNewEntity(entity.id)) {
      this.drawEntity(entity);
    } else {
      this.openExistingEntityDetails(entity);
    }
  }

  displayEntities(entities: MapEntity[], show: boolean) {
    if (entities && entities.length > 0) {
      if (entities.length === 1) {
        this.displaySingleEntity(entities[0], show);
      } else {
        this.displayMultipleEntities(entities, show);
      }
    }
  }

  private displayMultipleEntities(entities: MapEntity[], show: boolean) {
    execute(this.displayMultipleEntities.name, () => {
      mixpanel.track((show ? 'Display' : 'Hide') + ' Multiple Entities On Model', {
        'Entities Count': entities.length
      });
    });
  }

  private displaySingleEntity(entity: MapEntity, show: boolean) {
    execute(this.displaySingleEntity.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.track((show ? 'Display' : 'Hide') + ' Analytic On Model', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.track((show ? 'Display' : 'Hide') + ' Measurement On Model', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.track((show ? 'Display' : 'Hide') + ' Annotation On Model', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.track((show ? 'Display' : 'Hide') + ' Model Edit On Model', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  clickOnDrawEntity(entityType: EntityType | MultiEntityType, src: 'Map' | 'Sidenav' | 'Details box' = 'Map') {
    execute(this.clickOnDrawEntity.name, () => {
      if (entityType in AnalyticType || entityType === 'MULTI_POINT_DELTA_ELEVATION') {
        mixpanel.track('Click On Draw Analytic', { 'Analytics Type': entityType });
      } else if (entityType in MeasurementType || entityType === 'MULTI_POINT') {
        mixpanel.track('Click On Draw Measurement', { 'Measurements Type': entityType });
      } else if (entityType in AnnotationType) {
        mixpanel.track('Click On Draw Annotation', { 'Annotation Type': entityType, 'Created From': src });
      }
    });
  }

  private drawEntity(entity: MapEntity) {
    execute(this.drawEntity.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.track('Draw Analytic', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.track('Draw Measurement', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.track('Draw Annotation', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.track('Draw Model Edit', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  private openExistingEntityDetails(entity: MapEntity) {
    execute(this.openExistingEntityDetails.name, () => {
      if (entity.type in AnalyticType) {
        mixpanel.track('Open Existing Analytic Details', this.fetchAnalyticProperties(entity as Analytic));
      } else if (entity.type in MeasurementType) {
        mixpanel.track('Open Existing Measurement Details', this.fetchMeasurementProperties(entity as Measurement));
      } else if (entity.type in AnnotationType) {
        mixpanel.track('Open Existing Annotation Details', this.fetchAnnotationProperties(entity as Annotation));
      } else if (entity.type in ModelEditType) {
        mixpanel.track('Open Existing Model Edit Details', this.fetchModelEditAreaProperties(entity as ModelEdit));
      }
    });
  }

  createMeasurementFromDesign(designId: string, designName: string, layerName: string, entityId: string, entityType: EntityType) {
    execute(this.createMeasurementFromDesign.name, () =>
      mixpanel.track('Create Measurement From Design', {
        'Design ID': designId,
        'Design Name': designName,
        'Design Layer Name': layerName,
        'Design Entity ID': entityId,
        'Entity Type': entityType
      })
    );
  }

  createAnnotationFromImage(fileName: string, longitude: number, latitude: number, altitude: number, dateStr: string) {
    execute(this.createAnnotationFromImage.name, () =>
      mixpanel.track('Create Annotation From Image', {
        'Image Name': fileName,
        'Image Date': convertDateIfNecessary(dateStr),
        'Image Location Longitude': longitude,
        'Image Location Latitude': latitude,
        'Image Location Altitude': altitude
      })
    );
  }

  finishMultiMarkersCreation(entities: MapEntity[]) {
    execute(this.finishMultiMarkersCreation.name, () => {
      const firstEntity = entities?.[0];
      mixpanel.track('Finished Multi Marker Creation', {
        'Entity Type': firstEntity?.type,
        'Entity Base Name': firstEntity?.name.slice(0, firstEntity?.name.lastIndexOf(' ')),
        'Entity Group ID': firstEntity?.groupId,
        'Entity Layer ID': firstEntity?.layerId,
        'Entities Count': entities?.length
      });
    });
  }

  downloadEntitiesReport(entities: MapEntity[], type: 'All Entities' | 'Group Entities' | 'Single Entity' | 'Map Snapshot') {
    execute(this.downloadEntitiesReport.name, () => {
      mixpanel.people.increment(REPORT_DOWNLOADS);

      let properties: any = { 'Downloaded Type': type };
      switch (type) {
        case 'All Entities':
          properties = {
            ...properties,
            'Entities Count': entities?.length || 0
          };
          break;
        case 'Group Entities':
          properties = {
            ...properties,
            'Group ID': entities?.[0]?.groupId
          };
          break;
        case 'Single Entity':
          properties = {
            ...properties,
            ...this.fetchEntityProperties(entities?.[0])
          };
          break;
      }
      mixpanel.track('Download Report', properties);
    });
  }

  changeDeltaElevationVizOptions(entity: Analytic, activeTaskId: string, vizOptions: DeltaElevationVizOptions) {
    execute(this.changeDeltaElevationVizOptions.name, () => {
      mixpanel.track('Change Delta Elevation Overlay', {
        'Color Stops': vizOptions?.elevationRamp?.map(ramp => `${ramp.position}: ${ramp.color}`).join(', '),
        'Color Range Min': vizOptions?.deltaHeightsRange?.[activeTaskId]?.min,
        'Color Range Max': vizOptions?.deltaHeightsRange?.[activeTaskId]?.max,
        Opacity: vizOptions?.opacity,
        ...this.fetchAnalyticProperties(entity)
      });
    });
  }

  downloadFlightArtifact(siteId: string, taskId: string, artifact: GetArtifactResponse) {
    execute(this.downloadFlightArtifact.name, () => {
      mixpanel.track(`Download Flight Artifact`, {
        'Site ID': siteId,
        'Flight ID': taskId,
        'Artifact Name': artifact.fileName,
        'Artifact ID': artifact.id,
        'Artifact Type': artifact.type
      });
    });
  }

  generateFlightOrthophotoECW(siteId: string, taskId: string) {
    execute(this.generateFlightOrthophotoECW.name, () => {
      mixpanel.track(`Generate Flight Orthophoto ECW`, {
        'Site ID': siteId,
        'Flight ID': taskId
      });
    });
  }

  generateFlightImagesZip(siteId: string, taskId: string) {
    execute(this.generateFlightImagesZip.name, () => {
      mixpanel.track(`Generate Flight Images ZIP`, {
        'Site ID': siteId,
        'Flight ID': taskId
      });
    });
  }

  generateReport(report: ReportEntity) {
    execute(this.generateReport.name, () => {
      const props = this.fetchReportProperties(report);
      mixpanel.track(`Generate ${props['Report Type']} Report`, props);
    });
  }

  editReport(report: ReportEntity) {
    execute(this.editReport.name, () => {
      const props = this.fetchReportProperties(report);
      mixpanel.track(`Edit ${props['Report Type']} Report`, props);
    });
  }

  deleteReport(report: ReportEntity) {
    execute(this.deleteReport.name, () => {
      mixpanel.track(`Delete Report`, this.fetchReportProperties(report));
    });
  }

  downloadCrossSectionVolumeReport(reportData: CrossSectionVolumeReportData, paperSize: string) {
    execute(this.downloadCrossSectionVolumeReport.name, () => {
      mixpanel.track('Download Cross Section Volume Report', {
        ...this.fetchReportProperties({ ...reportData, type: ReportType.CROSS_SECTION_VOLUME }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  downloadCrossSectionProgressReport(reportData: CrossSectionProgressReportData, paperSize: string) {
    execute(this.downloadCrossSectionProgressReport.name, () => {
      mixpanel.track('Download Cross Section Progress Report', {
        ...this.fetchReportProperties({ ...reportData, type: ReportType.CROSS_SECTION_PROGRESS }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  downloadGridHeatmapReportReport(reportData: GridHeatmapReportData, paperSize: string) {
    execute(this.downloadGridHeatmapReportReport.name, () => {
      const reportType =
        reportData.gridReportType === GridReportType.EGHR ? ReportType.ELEVATION_GRID_HEATMAP : ReportType.VOLUME_GRID_HEATMAP;
      const reportTypeName =
        reportData.gridReportType === GridReportType.EGHR ? 'Elevation Grid Heatmap Report' : 'Volume Grid Heatmap Report';
      mixpanel.track(`Download ${reportTypeName}`, {
        ...this.fetchReportProperties({ ...reportData, type: reportType }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  downloadSurfaceGradeCheckingReport(reportData: SurfaceGradeCheckingReportData, paperSize: string) {
    execute(this.downloadSurfaceGradeCheckingReport.name, () => {
      mixpanel.track(`Download Surface Grade Checking Report`, {
        ...this.fetchReportProperties({ ...reportData, type: ReportType.SURFACE_GRADE_CHECKING }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  downloadRoadGradeCheckingReport(reportData: RoadGradeCheckingReportData, paperSize: string) {
    execute(this.downloadRoadGradeCheckingReport.name, () => {
      mixpanel.track(`Download Road Grade Checking Report`, {
        ...this.fetchReportProperties({ ...reportData, type: ReportType.ROAD_GRADE_CHECKING }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  downloadWaterFlowReport(reportData: WaterFlowReport, paperSize: string) {
    execute(this.downloadWaterFlowReport.name, () => {
      mixpanel.track('Download Water Flow Report', {
        ...this.fetchReportProperties({ ...reportData, type: ReportType.WATER_FLOW }),
        'Paper Size': paperSize.toUpperCase()
      });
    });
  }

  showReport(report: ReportEntity) {
    execute(this.showReport.name, () => {
      mixpanel.track('Show Report', this.fetchReportProperties(report));
    });
  }

  downloadDXF(entities: MapEntity[]) {
    execute(this.downloadDXF.name, () => {
      if (entities) {
        mixpanel.people.increment(DXF_DOWNLOADS);

        let properties;
        if (entities.length > 1) {
          properties = {
            'Downloaded Form': 'Group',
            'Group ID': entities[0].groupId
          };
        } else {
          properties = {
            'Downloaded Form': 'Single',
            ...this.fetchEntityProperties(entities[0])
          };
        }

        mixpanel.track('Download DXF', properties);
      }
    });
  }

  downloadDesignFile(design: Design | RoadDesign) {
    execute(this.downloadDesignFile.name, () => {
      if (design) {
        mixpanel.people.increment(DESIGN_DOWNLOADS);
        const properties = {
          'Downloaded Type': 'Single',
          'Downloaded Entity ID': design.id,
          'Downloaded Entity Name': design.name
        };
        mixpanel.track('Download Design File', properties);
      }
    });
  }

  downloadCSV(entities: MapEntity[]) {
    execute(this.downloadCSV.name, () => {
      if (entities) {
        mixpanel.people.increment(CSV_DOWNLOADS);

        let properties;
        if (entities.length > 1) {
          properties = {
            'Downloaded Type': 'Group',
            'Group ID': entities[0].groupId
          };
        } else {
          properties = {
            'Downloaded Type': 'Single',
            ...this.fetchEntityProperties(entities[0])
          };
        }
        mixpanel.track('Download CSV', properties);
      }
    });
  }

  importDesign(design: Partial<Design>, isMultiFileUpload: boolean) {
    execute(this.importDesign.name, () => {
      if (design) {
        mixpanel.people.increment(IMPORTED_DESIGNS);
        mixpanel.track('Import Design', {
          ...this.fetchDesignProperties(design),
          'Design Is Part Of Multi Upload': isMultiFileUpload
        });
      }
    });
  }

  importRoadDesign(roadDesign: RoadDesign) {
    execute(this.importRoadDesign.name, () => {
      if (roadDesign) {
        mixpanel.people.increment(IMPORTED_ROAD_DESIGNS);
        mixpanel.track('Import Road Design', this.fetchRoadDesignProperties(roadDesign));
      }
    });
  }

  updateDesign(design: Partial<Design>) {
    execute(this.updateDesign.name, () => {
      if (design) {
        mixpanel.track('Update Design', this.fetchDesignProperties(design));
      }
    });
  }

  updateRoadDesign(roadDesign: RoadDesign) {
    execute(this.updateRoadDesign.name, () => {
      if (roadDesign) {
        mixpanel.track('Update Road Design', this.fetchRoadDesignProperties(roadDesign));
      }
    });
  }

  displayDesign(design: Design | RoadDesign) {
    execute(this.displayDesign.name, () => {
      if (design.type === DesignType.DESIGN) {
        mixpanel.track('Display Design On Model', this.fetchDesignProperties(design));
      } else if (design.type === DesignType.ROAD_DESIGN) {
        mixpanel.track('Display Road Design On Model', this.fetchRoadDesignProperties(design));
      }
    });
  }

  deleteDesign(design: Design) {
    execute(this.deleteDesign.name, () => {
      if (design) {
        mixpanel.track('Delete Design', this.fetchDesignProperties(design));
      }
    });
  }

  deleteRoadDesign(roadDesign: RoadDesign) {
    execute(this.deleteRoadDesign.name, () => {
      if (roadDesign) {
        mixpanel.track('Delete Road Design', this.fetchRoadDesignProperties(roadDesign));
      }
    });
  }

  showDesignFeatureProperties(design: Design) {
    execute(this.showDesignFeatureProperties.name, () => {
      if (design) {
        mixpanel.track('Show Design Feature Properties', this.fetchDesignProperties(design));
      }
    });
  }

  updateIntegratedDesignNode(designNode: IntegrationDesignNode) {
    execute(this.updateIntegratedDesignNode.name, () => {
      if (designNode) {
        mixpanel.track('Update Integrated File Or Folder', this.fetchIntegrationDesignNodeProperties(designNode));
      }
    });
  }

  createCategoryBySynchronizedFolder(designNode: IntegrationDesignNode) {
    execute(this.createCategoryBySynchronizedFolder.name, () => {
      if (designNode) {
        mixpanel.track('Create Category By Synchronized Folder', this.fetchIntegrationDesignNodeProperties(designNode));
      }
    });
  }

  editDesignCategoryFromSyncDesignsDialog(designNode: IntegrationDesignNode, design: Design) {
    execute(this.editDesignCategoryFromSyncDesignsDialog.name, () => {
      if (designNode && design) {
        mixpanel.track('Edit Design Category From Synchronized Designs Dialog', {
          ...this.fetchIntegrationDesignNodeProperties(designNode),
          ...this.fetchDesignProperties(design)
        });
      }
    });
  }

  addSynchronizedDesignToSidenav(design: Design) {
    execute(this.addSynchronizedDesignToSidenav.name, () => {
      if (design) {
        mixpanel.track('Add Synchronized Design Sidenav', this.fetchDesignProperties(design));
      }
    });
  }

  removeSynchronizedDesignFromSidenav(design: Design) {
    execute(this.removeSynchronizedDesignFromSidenav.name, () => {
      if (design) {
        mixpanel.track('Remove Synchronized Design From Sidenav', this.fetchDesignProperties(design));
      }
    });
  }

  markDesignAsFavorite(design: Design | RoadDesign) {
    execute(this.markDesignAsFavorite.name, () => {
      if (design) {
        mixpanel.track('Mark Design As Favorite', {
          ...(design.type === DesignType.DESIGN ? this.fetchDesignProperties(design) : this.fetchRoadDesignProperties(design)),
          'Shared Version ID': design.sharedVersionId
        });
      }
    });
  }

  unmarkDesignAsFavorite(design: Design | RoadDesign) {
    execute(this.unmarkDesignAsFavorite.name, () => {
      if (design) {
        mixpanel.track('Unmark Design As Favorite', {
          ...(design.type === DesignType.DESIGN ? this.fetchDesignProperties(design) : this.fetchRoadDesignProperties(design)),
          'Shared Version ID': design.sharedVersionId
        });
      }
    });
  }

  changeDesignActiveVersion(design: Design, newActiveVersion: DesignVersion) {
    execute(this.changeDesignActiveVersion.name, () => {
      if (design && newActiveVersion) {
        mixpanel.track('Change Design Active Version', {
          ...this.fetchDesignProperties(design),
          'Design New Active Version Name': newActiveVersion.name
        });
      }
    });
  }

  syncDesignVersion(design: Design, versionToSync: DesignVersion) {
    execute(this.syncDesignVersion.name, () => {
      if (design && versionToSync) {
        mixpanel.track('Synchronize Design Version', {
          ...this.fetchDesignProperties(design),
          'Design Version To Sync Name': versionToSync.name
        });
      }
    });
  }

  shareResourceLink(request: {
    link: string;
    siteName: string;
    flightDateLabel: string;
    existingUsersEmails: string[];
    newUsersEmails: string[];
    type: ShareResourceRequest.TypeEnum;
  }) {
    execute(this.shareResourceLink.name, () =>
      mixpanel.track('Resource Link Shared', {
        'Resource Link': request.link,
        'Resource Type': request.type,
        'Site Name': request.siteName,
        'Flight Mission Date': request.flightDateLabel,
        'Existing Users Emails': request.existingUsersEmails,
        'New Users Emails': request.newUsersEmails
      })
    );
  }

  viewGanttChart(projectPlan: ProjectPlan) {
    execute(this.viewGanttChart.name, () =>
      mixpanel.track('View Gantt chart', {
        ...this.fetchProjectPlanProperties(projectPlan)
      })
    );
  }

  toggleGanttShowGeometric(projectPlan: ProjectPlan, show: boolean) {
    execute(this.toggleGanttShowGeometric.name, () =>
      mixpanel.track('Toggle Gantt Show Geometric', {
        ...this.fetchProjectPlanProperties(projectPlan),
        'Show Geometric': show
      })
    );
  }

  toggleGanttShowCriticalPath(projectPlan: ProjectPlan, show: boolean) {
    execute(this.toggleGanttShowCriticalPath.name, () =>
      mixpanel.track('Toggle Gantt Show Critical Path', {
        ...this.fetchProjectPlanProperties(projectPlan),
        'Show Geometric': show
      })
    );
  }

  changeGanttZoomLevel(projectPlan: ProjectPlan, zoom: string) {
    execute(this.changeGanttZoomLevel.name, () =>
      mixpanel.track('Change Gantt Zoom Level', {
        ...this.fetchProjectPlanProperties(projectPlan),
        'Zoom Level': zoom
      })
    );
  }

  searchGantt(projectPlan: ProjectPlan, searchStr: string) {
    execute(this.searchGantt.name, () =>
      mixpanel.track('Search Gantt', {
        ...this.fetchProjectPlanProperties(projectPlan),
        'Search String': searchStr
      })
    );
  }

  changeGanttVersion(toProjectPlan: ProjectPlan) {
    execute(this.changeGanttVersion.name, () =>
      mixpanel.track('Change Gantt Version', {
        ...this.fetchProjectPlanProperties(toProjectPlan)
      })
    );
  }

  uploadProjectPlan(name: string, version: string) {
    execute(this.uploadProjectPlan.name, () =>
      mixpanel.track('Upload Project Plan', {
        'Project Plan File Name': name,
        'Project Plan Version': version
      })
    );
  }

  completeMergeProjectPlanConflicts(fromVersionId: string, toVersionId: string, conflictsCount: number) {
    execute(this.completeMergeProjectPlanConflicts.name, () =>
      mixpanel.track('Complete Merge Project Plan Conflicts', {
        'From Project Plan ID': fromVersionId,
        'To Project Plan ID': toVersionId,
        'Number Of Conflicts': conflictsCount
      })
    );
  }

  deleteAllProjectPlanVersions() {
    execute(this.deleteAllProjectPlanVersions.name, () => mixpanel.track('Delete All Project Plan Versions'));
  }

  deleteProjectPlanVersion(projectPlan: ProjectPlan) {
    execute(this.deleteProjectPlanVersion.name, () =>
      mixpanel.track('Delete Project Plan Version', {
        ...this.fetchProjectPlanProperties(projectPlan)
      })
    );
  }

  downloadActivityChart(activity: Activity) {
    execute(this.downloadActivityChart.name, () => mixpanel.track('Download Activity Chart', this.fetchActivityProperties(activity)));
  }

  openExistingActivityDetails(activityData: ActivityData) {
    execute(this.openExistingActivityDetails.name, () =>
      mixpanel.track('Open Existing Activity Details', this.fetchActivityProperties(activityData))
    );
  }

  openActivityDetailsChartTab(activity: Activity) {
    execute(this.openActivityDetailsChartTab.name, () =>
      mixpanel.track('Open Activity Details Chart Tab', this.fetchActivityProperties(activity))
    );
  }

  editActivity(activityData: ActivityData) {
    execute(this.editActivity.name, () =>
      mixpanel.track('Edit Activity', {
        ...this.fetchActivityProperties(activityData),
        'Activity Type': activityData.activityType,
        'Activity Quantity': activityData.amount,
        'Activity Owner ID': activityData.activityOwner,
        'Activity Actual Start Date': convertDateIfNecessary(activityData.actualStartDate),
        'Activity Actual End Date': convertDateIfNecessary(activityData.actualEndDate),
        'Activity Description': activityData.description,
        'Activity Is Tracked': activityData.tracked
      })
    );
  }

  associatePlannedMeasurementFromDesign(
    designData: AssociateDesignData,
    projectPlanData: { id: string; version: string },
    activity: Activity,
    measurementData: ActivityMeasurement
  ) {
    execute(this.associatePlannedMeasurementFromDesign.name, () =>
      mixpanel.track('Associate Planned Measurement With Activity From Design', {
        'Design ID': designData.id,
        'Design Name': designData.name,
        'Design Layer Name': designData.layer,
        'Design Entity ID': designData.featureId,
        ...this.fetchActivityMeasurementProperties(projectPlanData, activity, measurementData)
      })
    );
  }

  associatePlannedMeasurementManually(
    projectPlanData: { id: string; version: string },
    activity: Activity,
    measurementData: ActivityMeasurement
  ) {
    execute(this.associatePlannedMeasurementManually.name, () =>
      mixpanel.track(
        'Associate Planned Measurement With Activity Manually',
        this.fetchActivityMeasurementProperties(projectPlanData, activity, measurementData)
      )
    );
  }

  associateActualMeasurements(projectPlanData: { id: string; version: string }, activity: Activity) {
    execute(this.associateActualMeasurements.name, () =>
      mixpanel.track(
        'Associate Actual Measurements With Activity',
        this.fetchActivityMeasurementProperties(projectPlanData, activity, {
          type: ActivityMeasurementType.ACTUAL
        } as Partial<ActivityMeasurement>)
      )
    );
  }

  saveNewActivityMeasurements(activity: Activity, count: number) {
    execute(this.saveNewActivityMeasurements.name, () =>
      mixpanel.track('Save New Activity Measurements', {
        'Activity Type': activity.activityType,
        'Activity ID': activity.id,
        'Count of Measurements': count
      })
    );
  }

  saveActivityMeasurementsEditing(activity: Activity, count: number) {
    execute(this.saveActivityMeasurementsEditing.name, () =>
      mixpanel.track('Save Activity Measurements Editing', {
        'Activity Type': activity.activityType,
        'Activity ID': activity.id,
        'Count of Measurements': count
      })
    );
  }

  saveActivityMeasurementsDeletion(activity: Activity, count: number) {
    execute(this.saveActivityMeasurementsDeletion.name, () =>
      mixpanel.track('Save Activity Measurements Deletion', {
        'Activity Type': activity.activityType,
        'Activity ID': activity.id,
        'Count of Measurements': count
      })
    );
  }

  editActivityMeasurement(projectPlanData: { id: string; version: string }, activity: Activity, measurementData: ActivityMeasurement) {
    execute(this.editActivityMeasurement.name, () =>
      mixpanel.track('Edit Activity Measurement', this.fetchActivityMeasurementProperties(projectPlanData, activity, measurementData))
    );
  }

  deleteActivityMeasurement(projectPlanData: { id: string; version: string }, activity: Activity, measurementData: ActivityMeasurement) {
    execute(this.deleteActivityMeasurement.name, () =>
      mixpanel.track('Delete Activity Measurement', this.fetchActivityMeasurementProperties(projectPlanData, activity, measurementData))
    );
  }

  updateMultipleUsersFinished(userUpdatesCount: number, errorsCount: number) {
    execute(this.updateMultipleUsersFinished.name, () =>
      mixpanel.track('Update Multiple Users Finished', {
        'Total User Updates Count': userUpdatesCount,
        'Total Errors Count': errorsCount
      })
    );
  }

  private fetchAnalyticProperties(entity: Analytic) {
    const props = {
      'Analytics Type': entity.type,
      'Analytics ID': isNewEntity(entity.id) ? 'New' : entity.id,
      'Analytics Name': entity.name,
      'Group ID': entity.groupId,
      'Layer ID': entity.layerId,
      'Source Model': entity.sourceModel
    };
    if (entity.type in EntityTypeWithBaseSurface && entity.baseSurface) {
      return {
        ...props,
        ...this.fetchBaseSurfaceProperties(entity.baseSurface)
      };
    } else {
      return props;
    }
  }

  private fetchMeasurementProperties(entity: Measurement) {
    const props = {
      'Measurements Type': entity.type,
      'Measurements ID': isNewEntity(entity.id) ? 'New' : entity.id,
      'Measurements Name': entity.name,
      'Group ID': entity.groupId,
      'Layer ID': entity.layerId,
      'Flight ID': entity.taskId,
      'Source Model': entity.sourceModel
    };
    if (entity.type in EntityTypeWithBaseSurface && entity.baseSurface) {
      return {
        ...props,
        ...this.fetchBaseSurfaceProperties(entity.baseSurface)
      };
    } else {
      return props;
    }
  }

  private fetchBaseSurfaceProperties(baseSurface: BaseSurface) {
    if (baseSurface) {
      const baseSurfaceType = {
        [BaseSurfaceType.CUSTOMELEVATION]: 'Custom Elevation',
        [BaseSurfaceType.DESIGN]: 'Design',
        [BaseSurfaceType.INTERPOLATED]: 'Interpolated',
        [BaseSurfaceType.MINELEVATION]: 'Min Elevation',
        [BaseSurfaceType.TASK]: 'Flight'
      };

      return {
        'Reference Surface Type': baseSurface.isTarget ? SurfaceReferenceType.TARGET : SurfaceReferenceType.BASE,
        'Reference Surface Name': this.siteQuery.getBaseSurfaceName(baseSurface, false),
        'Reference Surface': baseSurfaceType[baseSurface.type],
        'Reference Surface Id': baseSurface.id
      };
    }
  }

  private fetchAnnotationProperties(entity: Annotation) {
    return {
      'Annotation ID': handleEntityId(entity.id),
      'Annotation Name': entity.name,
      'Annotation Date': convertDateIfNecessary(entity.date),
      'Group ID': entity.groupId,
      'Layer ID': entity.layerId,
      'Count Of Attachments': entity.attachments?.filter(a => !a.markedForDelete).length ?? 0,
      'Count Of Notes': entity.notes?.length ?? 0,
      'Source Model': entity.sourceModel,
      'Count Of Drawings': entity.drawings?.filter(d => !d.markedForDelete).length ?? 0,
      'Position Is Hidden': getYesNo(entity.hiddenPosition)
    };
  }

  private fetchAnnotationFileProperties(attachment: AnnotationFile) {
    return {
      'Attachment ID': handleEntityId(attachment.id),
      'Attachment Name': attachment.name,
      'Attachment Size': attachment.size,
      'Attachment Longitude': attachment.longitude,
      'Attachment Latitude': attachment.latitude,
      'Attachment Altitude': attachment.altitude,
      'Attachment Uploaded By User ID': attachment.uploadedBy.userId,
      'Attachment Uploaded By User Name': formatName(attachment.uploadedBy),
      'Attachment Uploaded By User Email': attachment.uploadedBy.email,
      'Attachment Show On Map': attachment.showOnMap
    };
  }

  private fetchModelEditAreaProperties(entity: ModelEdit) {
    return {
      'Model Edit Area ID': isNewEntity(entity.id) ? 'New' : entity.id,
      'Model Edit Area Name': entity.name,
      'Model Edit Area Type': entity.type,
      'Source Model': entity.sourceModel
    };
  }

  private fetchDesignProperties(design: Partial<Design>) {
    return {
      'Design Category ID': design.categoryId,
      'Design ID': design.id,
      'Design Name': design.name,
      'Design Has Surface': design.hasSurface,
      'Design File Name': design.fileName,
      'Design Active Version': design.versions.find(version => version.activeVersion).name,
      'Is 3D': getYesNo(design.cesium3DReady),
      'Is 2D': getYesNo(design.jsonReady)
    };
  }

  private fetchRoadDesignProperties(roadDesign: RoadDesign) {
    return {
      'Design Category ID': roadDesign.categoryId,
      'Design ID': roadDesign.id,
      'Design Name': roadDesign.name,
      'Design Type': roadDesign.roadDesignType,
      'Design Station Naming Format': roadDesign.stationNamingFormatType,
      'Design Horizontal/Top Surface File Name': roadDesign.file1Name,
      'Design Vertical/Bottom Surface File Name': roadDesign.file2Name,
      'Design Cross Section/Center Line File Name': roadDesign.file3Name
    };
  }

  private fetchIntegrationDesignNodeProperties(designNode: IntegrationDesignNode) {
    return {
      'Integrated Design Node Type': designNode.type,
      'Integrated Design Node Name': designNode.name,
      'Integrated Design Node ID': designNode.id,
      'Integrated Design Node Path': designNode.path,
      'Integrated Design Node Design Type': designNode.designType,
      'Integrated Design Node Synchronized': getYesNo(designNode.sync)
    };
  }

  private fetchEntityProperties(entity: MapEntity) {
    if (!entity) {
      return {};
    }

    return {
      'Downloaded Entity Type': entity.type,
      'Downloaded Entity ID': isNewEntity(entity.id) ? 'New' : entity.id,
      'Downloaded Entity Name': entity.name
    };
  }

  private fetchProjectPlanProperties(projectPlan: ProjectPlan) {
    return {
      'Project Plan File Name': projectPlan.name,
      'Project Plan ID': projectPlan.id,
      'Project Plan Creation Time': convertDateIfNecessary(projectPlan.creationTime),
      'Project Plan Active': getYesNo(projectPlan.active),
      'Project Plan Version': projectPlan.version
    };
  }

  private fetchActivityProperties(activityData: Activity) {
    return {
      'Activity Name': activityData.name,
      'Activity ID': activityData.id,
      'Activity Source Unique ID': activityData.sourceActivityUniqueId,
      'Project Plan ID': activityData.projectPlanId,
      'Project Plan Version': activityData.version
    };
  }

  private fetchActivityMeasurementProperties(
    projectPlanData: { id: string; version: string },
    activity: Activity,
    measurementData: ActivityMeasurement
  ) {
    return {
      'Project Plan ID': projectPlanData.id,
      'Project Plan Version': projectPlanData.version,
      'Activity Type': activity.activityType,
      'Activity ID': activity.id,
      'Activity Measurement Name': measurementData?.name,
      'Activity Measurement ID': measurementData?.id,
      'Activity Measurement Type': measurementData?.measurementType,
      'Activity Measurement Source Type': measurementData?.sourceType,
      'Activity Measurement Reference Surface': measurementData?.baseSurface?.type
    };
  }

  private fetchReportProperties(report: ReportEntity) {
    switch (report.type) {
      case ReportType.CROSS_SECTION_PROGRESS:
        return {
          'Report ID': report.id,
          'Report Type': 'Cross Section Progress',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Is Drawn Center Line': getYesNo(isDefined(report.drawnCenterLine)),
          'Centerline Road Design ID': report.designCenterLine?.id,
          'Drawn Centerline Points Count': report.drawnCenterLine?.drawnCenterLine?.length,
          'Design Station Naming Format': report.drawnCenterLine.stationNamingFormatType || report.designCenterLine.stationNamingFormatType,
          'Road Designs Count': report.roadDesigns?.length ?? 0,
          'Designs Count': report.designs?.designs?.length ?? 0,
          'Tasks Count': report.tasks?.tasks?.length ?? 0,
          'Task Surface Type': report.tasks.surfaceType,
          'Start Station': report.startStation,
          'End Station': report.endStation,
          'Station Interval': report.sectionInterval,
          'Station Max WIdth Left': report.sectionMaxWidthLeft,
          'Station Max WIdth Right': report.sectionMaxWidthRight,
          'Show Breakline Labels': getYesNo(report.showBreakLineLabels),
          'Show One Chart Per Row': getYesNo(report.showOneChartPerRow)
        };

      case ReportType.CROSS_SECTION_VOLUME:
        return {
          'Report ID': report.id,
          'Report Type': 'Cross Section Volume',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Flight ID': report.taskId,
          'Design ID': report.roadDesignId,
          'Base Surface': report.surface1,
          'Start Station': report.startStation,
          'End Station': report.endStation,
          'Station Interval': report.sectionInterval,
          'Station Max WIdth Left': report.sectionMaxWidthLeft,
          'Station Max WIdth Right': report.sectionMaxWidthRight,
          'Factor Cut': report.factorCut,
          'Factor Fill': report.factorFill,
          'Show Breakline Labels': getYesNo(report.showBreakLineLabels),
          'Show One Chart Per Row': getYesNo(report.showOneChartPerRow)
        };

      case ReportType.VOLUME_GRID_HEATMAP:
      case ReportType.ELEVATION_GRID_HEATMAP:
        return {
          'Report ID': report.id,
          'Report Type': report.type === ReportType.ELEVATION_GRID_HEATMAP ? 'Elevation Grid Heatmap' : 'Volume Grid Heatmap',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Manual Bounds Polygon': isDefined(report.reportAreaPolygon),
          'Base Surface ID': report.baseSurface.id,
          'Base Surface Type': report.baseSurface.type,
          'Base Surface Name': report.baseSurface.name,
          'Target Surface ID': report.comparisonSurface.id,
          'Target Surface Type': report.comparisonSurface.type,
          'Target Surface Name': report.comparisonSurface.name,
          'Task Surface Type': report.taskSurfaceType,
          'Grid Azimuth': report.gridAzimuth,
          'Heatmap Interval': report.heatMapInterval,
          'Heatmap Smoothing': report.smoothHeatMapEdges,
          'Factor Cut': report.factorCut,
          'Factor Fill': report.factorFill
        };
      case ReportType.SURFACE_GRADE_CHECKING:
        return {
          'Report ID': report.id,
          'Report Type': 'Surface Grade Checking',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Surface ID': report.surfaceId,
          'Surface Name': report.surfaceName,
          'Surface Type': report.surfaceGradeCheckingType,
          'Surface Custom Elevation': report.customElevation,
          'Model Type': report.demType,
          'Decimal Point Accuracy': report.roundingDigits
        };
      case ReportType.ROAD_GRADE_CHECKING:
        return {
          'Report ID': report.id,
          'Report Type': 'Road Grade Checking',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Surface ID': report.surfaceId,
          'Surface Name': report.surfaceName,
          'Surface Type': report.surfaceType,
          'Decimal Point Accuracy': report.roundingDigits
        };
      case ReportType.WATER_FLOW:
        return {
          'Report ID': report.id,
          'Report Type': 'Water Flow',
          'Report Name': report.name,
          'Site ID': report.siteId,
          'Task ID': report.orthoPhotoTaskId,
          'Manual Bounds Polygon': isDefined(report.reportAreaPolygon),
          'Task Surface Type': report.taskSurfaceType,
          'Arrows Interval': report.spacing,
          'Presentation Format': report.mapFlag ? 'Slopes Heatmap With Arrows' : 'Colored Arrows'
        };
    }
  }

  private fetchSiteProperties(site: Site) {
    if (!site) {
      return;
    }
    const siteProperties = {
      'Site Address': site.address,
      'Site Category ID': site.category?.id,
      'Site Category Name': site.category?.name,
      'Site Subcategory': site.category?.subCategory,
      'Site Custom Category': site.customCategory,
      ...addPrefixToObjectKeys(this.fetchCoordinateSystemProperties(site.coordinateSystem), 'Site '),
      'Site Description': site.description,
      'Site End Date': convertDateIfNecessary(site.endDate),
      'Site ID': site.id,
      'Site Latitude': site.latitude,
      'Site Longitude': site.longitude,
      'Site Max Number Of Flights': site.maxNumberOfFlights,
      'Site Name': site.name,
      'Site Start Date': convertDateIfNecessary(site.startDate),
      'Site Units': site.units,
      'Site Volume Units': site.volumeUnits,
      'Site Users': site.users?.map(user => user.email),
      'Site Group ID': site.siteGroup?.id,
      'Site Group Name': site.siteGroup?.name,
      'Site 3D Custom Properties':
        site.designsCustomProperties?.map(customProperty => `${customProperty.name} (${customProperty.valueType})`).join(', ') || '',
      'Site Annotations Association':
        site.associations?.find(association => association.artifact === ArtifactEnum.ANNOTATION)?.integration || '',
      'Site Designs Association': site.associations?.find(association => association.artifact === ArtifactEnum.DESIGN)?.integration || ''
    };

    return siteProperties;
  }

  private fetchAssociationProjectProperties(project: IntegrationProject) {
    if (!project) {
      return;
    }
    return {
      'Integration Project ID': project.remoteSiteId,
      'Integration Project Name': project.name,
      'Integration Name': project.integration,
      'Integration Artifact': project.artifact
    };
  }

  private fetchCoordinateSystemProperties(coordinateSystem: CoordinateSystem) {
    if (!coordinateSystem) {
      return;
    }

    return {
      'Coordinate System ID': coordinateSystem.id,
      'Coordinate System Name': coordinateSystem.name,
      'Coordinate System Code': coordinateSystem.code,
      'Local Coordinate System ID': coordinateSystem.localCoordinateSystem?.id
    };
  }

  private fetchTaskProperties(task: Task) {
    if (!task) {
      return;
    }
    return {
      'Flight Description': task.description,
      'Flight Drone Type': task.droneType,
      'Flight Geo Referencing Method': task.georefMethod,
      'Flight ID': task.id,
      'Flight Count Of Images': task.imagesCount,
      'Flight Images Size': task.imagesSize,
      'Flight Mission Date': task.flightDateLabel,
      'Flight Name': task.name,
      'Flight Source': task.flightSource,
      'Flight With Rolling Shutter': getYesNo(task.rollingShutter),
      'Flight With RTK': getYesNo(task.rtk),
      'Flight RTK Offset': task.rtkOffset ?? 'No',
      'Flight Warnings': task.warnings,
      'Site ID': task.siteId
    };
  }

  private fetchUserProperties(user: User) {
    if (!user) {
      return;
    }

    return {
      'User First Name': user.firstName,
      'User Last Name': user.lastName,
      'User Email': user.email,
      'User Access Level': user.accessLevel,
      'User ID': user.id,
      'User Employee Number': user.employeeNumber,
      'User Employee Title': user.employeeTitle?.title,
      'User Phone': user.phone
    };
  }

  changeMapOpacity(opacity: number) {
    execute(this.changeMapOpacity.name, () => {
      mixpanel.track('Change Map Opacity', { Opacity: opacity });
    });
  }

  changeMapStyle(mapStyle: MapStyle) {
    execute(this.changeMapStyle.name, () => {
      mixpanel.track('Change Map Style', { 'Map Style': MapStyle[mapStyle] });
    });
  }

  setWatchingLocation(isWatching: boolean, userLocation?: Cartographic) {
    execute(this.setWatchingLocation.name, () => {
      mixpanel.track('User Location Tracking ', {
        Status: isWatching ? 'Started' : 'Stopped',
        'User Location': userLocation
      });
    });
  }

  announcementMenuOpened() {
    execute(this.announcementMenuOpened.name, () => {
      mixpanel.track('Announcement Menu Opened');
    });
  }

  announcementLinkBtnClicked(announcement: Announcement) {
    execute(this.announcementLinkBtnClicked.name, () => {
      mixpanel.track('Announcement Link Button Clicked', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementDialogCloseBtnClick(announcement: Announcement) {
    execute(this.announcementDialogCloseBtnClick.name, () => {
      mixpanel.track('Announcement Closed By User', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementDialogSnoozeBtnClick(announcement: Announcement) {
    execute(this.announcementDialogSnoozeBtnClick.name, () => {
      mixpanel.track('Announcement Snoozed', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementOpenedBySystem(announcement: Announcement) {
    execute(this.announcementOpenedBySystem.name, () => {
      mixpanel.track('Announcement Opened By System', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementOpenedFromMenu(announcement: Announcement) {
    execute(this.announcementOpenedFromMenu.name, () => {
      mixpanel.track('Announcement Opened By User', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementReadStatusChanged(announcement: Announcement) {
    execute(this.announcementReadStatusChanged.name, () => {
      mixpanel.track('Announcement Read Status Changed', this.fetchAnnouncementProperties(announcement));
    });
  }

  announcementImgEnlarged(announcement: Announcement) {
    execute(this.announcementImgEnlarged.name, () => {
      mixpanel.track('Announcement Image Enlarged', this.fetchAnnouncementProperties(announcement));
    });
  }

  fetchAnnouncementProperties(announcement: Announcement) {
    if (!isDefined(announcement)) {
      return;
    }

    const announcementDetails = {
      'Announcement ID': announcement.id,
      'Announcement Title': announcement.title,
      'Announcement Subtitle': announcement.subtitle,
      'Announcement Media Link': announcement.mediaLink,
      'Announcement Media Type': announcement.mediaType,
      'Announcement Button Text': announcement.buttonText,
      'Announcement Button Link': announcement.buttonLink,
      'Announcement Read Status': announcement.read,
      'Announcement Seen Status': announcement.seen
    };

    return announcementDetails;
  }

  resourceOpenedByDirectURL(resourceId: string, resourceType: string) {
    execute(this.resourceOpenedByDirectURL.name, () => {
      mixpanel.track('Resource Opened By Direct URL', { 'Resource ID': resourceId, 'Resource Type': resourceType });
    });
  }

  addResourceLink(fromResourceId: string, fromResourceType: ResourceLinkType, toResourceId: string, toResourceType: ResourceLinkType) {
    execute(this.addResourceLink.name, () =>
      mixpanel.track('Add Resource Link', {
        'From Resource ID': handleEntityId(fromResourceId),
        'From Resource Type': fromResourceType,
        'To Resource ID': handleEntityId(toResourceId),
        'To Resource Type': toResourceType
      })
    );
  }

  removeResourceLink(fromResourceId: string, fromResourceType: ResourceLinkType, toResourceId: string, toResourceType: ResourceLinkType) {
    execute(this.removeResourceLink.name, () =>
      mixpanel.track('Remove Resource Link', {
        'From Resource ID': handleEntityId(fromResourceId),
        'From Resource Type': fromResourceType,
        'To Resource ID': handleEntityId(toResourceId),
        'To Resource Type': toResourceType
      })
    );
  }

  saveResourceLinks(resourceId: string, resourceType: ResourceLinkType) {
    execute(this.saveResourceLinks.name, () =>
      mixpanel.track('Save Resource Links', {
        'Resource ID': resourceId,
        'Resource Type': resourceType
      })
    );
  }

  clickOnAddDrawing(annotation: Annotation) {
    execute(this.clickOnAddDrawing.name, () =>
      mixpanel.track('Annotation Card Click On Add Drawing', {
        'Annotation ID': handleEntityId(annotation.id),
        'Annotation Name': annotation.name
      })
    );
  }

  clickOnDrawingItem(annotation: Annotation, drawing: Drawing) {
    execute(this.clickOnDrawingItem.name, () =>
      mixpanel.track('Annotation Card Click On Drawing Item', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  clickOnEditDrawing(annotation: Annotation, drawing: Drawing) {
    execute(this.clickOnEditDrawing.name, () =>
      mixpanel.track('Annotation Card Drawing Menu Click On Edit', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  clickOnDrawingOnMap(annotation: Annotation, drawing: Drawing) {
    execute(this.clickOnDrawingOnMap.name, () =>
      mixpanel.track('Annotation WBRD Map Click On Drawing', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  toggleAllDrawingLabelsViz(annotation: Annotation, hide: boolean) {
    execute(this.toggleAllDrawingLabelsViz.name, () =>
      mixpanel.track(`Annotation WBRD ${hide ? 'Hide' : 'Show'} All Drawing Labels`, {
        'Annotation ID': handleEntityId(annotation.id),
        'Annotation Name': annotation.name
      })
    );
  }

  toggleDrawingLabelViz(annotation: Annotation, drawing: Drawing, show: boolean) {
    execute(this.toggleDrawingLabelViz.name, () =>
      mixpanel.track(`Annotation WBRD ${show ? 'Show' : 'Hide'} Drawing Label`, this.fetchDrawingProperties(annotation, drawing))
    );
  }

  whiteboardClickOnDrawDrawing(annotation: Annotation, drawingType: DrawingType) {
    execute(this.whiteboardClickOnDrawDrawing.name, () =>
      mixpanel.track('Annotation WBRD Click On Draw Drawing', {
        'Annotation ID': handleEntityId(annotation.id),
        'Annotation Name': annotation.name,
        'Drawing Type': drawingType
      })
    );
  }

  whiteboardDrawDrawing(annotation: Annotation, drawing: Drawing) {
    execute(this.whiteboardDrawDrawing.name, () =>
      mixpanel.track('Annotation WBRD Draw Drawing', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  whiteboardClickOnEditDrawingStyle(annotation: Annotation, styleProperty: DrawingStylePropName) {
    execute(this.whiteboardClickOnEditDrawingStyle.name, () =>
      mixpanel.track('Annotation WBRD Click On Edit Drawing Style Property', {
        'Annotation ID': handleEntityId(annotation.id),
        'Annotation Name': annotation.name,
        'Style Property Was Clicked': styleProperty
      })
    );
  }

  whiteboardEditDrawingStyleProp(annotation: Annotation, drawing: Drawing, styleProperty: DrawingStylePropName) {
    execute(this.whiteboardEditDrawingStyleProp.name, () =>
      mixpanel.track('Annotation WBRD Edit Drawing Style Property', {
        ...this.fetchDrawingProperties(annotation, drawing),
        'Style Property Was Edited': styleProperty
      })
    );
  }

  whiteboardEditDrawingName(annotation: Annotation, drawing: Drawing) {
    execute(this.whiteboardEditDrawingName.name, () =>
      mixpanel.track('Annotation WBRD Edit Drawing Name', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  whiteboardDeleteDrawing(annotation: Annotation, drawing: Drawing) {
    execute(this.whiteboardDeleteDrawing.name, () =>
      mixpanel.track('Annotation WBRD Delete Drawing', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  /** To check if performance issues will occur */
  whiteboardDragDrawing(annotation: Annotation, drawing: Drawing) {
    execute(this.whiteboardDragDrawing.name, () =>
      mixpanel.track('Annotation WBRD Drag Drawing', this.fetchDrawingProperties(annotation, drawing))
    );
  }

  whiteboardCloseDrawingTools(annotation: Annotation, isChangesSaved: boolean) {
    execute(this.whiteboardCloseDrawingTools.name, () =>
      mixpanel.track('Annotation WBRD Close Drawing Tools', {
        'Annotation ID': handleEntityId(annotation.id),
        'Annotation Name': annotation.name,
        'Changes Saved': getYesNo(isChangesSaved)
      })
    );
  }

  saveNewDrawings(annotation: Annotation, drawings: Drawing[]) {
    drawings.forEach(drawing =>
      execute(this.saveNewDrawings.name, () =>
        mixpanel.track('Annotation Save New Drawing', this.fetchDrawingProperties(annotation, drawing))
      )
    );
  }

  saveDrawingsEditing(annotation: Annotation, drawings: Drawing[]) {
    drawings.forEach(drawing =>
      execute(this.saveDrawingsEditing.name, () =>
        mixpanel.track('Annotation Save Drawing Editing', this.fetchDrawingProperties(annotation, drawing))
      )
    );
  }

  saveDrawingsDeletion(annotation: Annotation, drawings: Drawing[]) {
    drawings.forEach(drawing =>
      execute(this.saveDrawingsDeletion.name, () =>
        mixpanel.track('Annotation Save Drawing Deletion', this.fetchDrawingProperties(annotation, drawing))
      )
    );
  }

  toggleAnnotationPositionViz(annotation: Annotation, hide: boolean) {
    execute(this.toggleAnnotationPositionViz.name, () =>
      mixpanel.track(`Annotation ${hide ? 'Hide' : 'Show'} Geo-Position`, this.fetchAnnotationProperties(annotation))
    );
  }

  generateAnnotationSnapshot(annotation: Annotation, isManual: boolean) {
    execute(this.generateAnnotationSnapshot.name, () =>
      mixpanel.track(`Annotation Generate Snapshot`, {
        ...this.fetchAnnotationProperties(annotation),
        'Is Manual Snapshot': isManual
      })
    );
  }

  toggleAnnotationAttachmentShowOnMap(annotation: Annotation, attachment: AnnotationFile, show: boolean) {
    execute(this.toggleAnnotationAttachmentShowOnMap.name, () =>
      mixpanel.track(`Annotation Attachment ${show ? 'Show' : 'Hide'} On Map`, {
        ...this.fetchAnnotationProperties(annotation),
        ...this.fetchAnnotationFileProperties(attachment)
      })
    );
  }

  private fetchDrawingProperties(annotation: Annotation, drawing: Drawing) {
    if (!isDefined(drawing)) {
      return;
    }
    return {
      'Annotation ID': handleEntityId(annotation.id),
      'Annotation Name': annotation.name,
      'Drawing ID': isNewDrawing(drawing.id) ? 'New' : drawing.id,
      'Drawing Name': drawing.name,
      'Drawing Type': drawing.type,
      'Drawing Flight ID': drawing.taskId,
      'Drawing Label Is Hidden': getYesNo(drawing.hiddenLabel),
      'Drawing Style Fill Color': drawing.style.fillColor ?? '',
      'Drawing Style Fill Opacity': drawing.style.fillOpacityPercent ?? '',
      'Drawing Style Line Color': drawing.style.lineColor ?? '',
      'Drawing Style Line Width': drawing.style.lineWidthPx ?? ''
    };
  }

  addGCPsFromFile(task: Task, gcpCount: number) {
    execute(this.addGCPsFromFile.name, () =>
      mixpanel.track('Add GCPs From File', {
        ...this.fetchTaskProperties(task),
        'GCP Count': gcpCount
      })
    );
  }

  addGCPsFromPreviousFlight(task: Task, prevTask: Task, gcpCount: number) {
    execute(this.addGCPsFromPreviousFlight.name, () =>
      mixpanel.track('Add GCPs From Previous Flight', {
        ...this.fetchTaskProperties(task),
        ...addPrefixToObjectKeys(this.fetchTaskProperties(prevTask), 'Prev '),
        'GCP Count': gcpCount
      })
    );
  }

  addMonument(task: Task, name: string, selectedOrthoTaskId: string) {
    execute(this.addMonument.name, () =>
      mixpanel.track('Add Monument', {
        ...this.fetchTaskProperties(task),
        'GCP Name': name,
        'Selected Flight ID': selectedOrthoTaskId
      })
    );
  }

  saveGCPMarks(task: Task, gcp: GCPItem) {
    execute(this.saveGCPMarks.name, () =>
      mixpanel.track('Save GCP Marks', {
        ...this.fetchGCPProperties(gcp),
        ...this.fetchTaskProperties(task)
      })
    );
  }

  removeGCPMarks(task: Task, gcp: GCPItem) {
    execute(this.removeGCPMarks.name, () =>
      mixpanel.track('Remove GCP Marks', {
        ...this.fetchGCPProperties(gcp),
        ...this.fetchTaskProperties(task)
      })
    );
  }

  changeGCPType(task: Task, gcp: GCPItem) {
    execute(this.changeGCPType.name, () =>
      mixpanel.track('Change GCP Type', {
        ...this.fetchGCPProperties(gcp),
        ...this.fetchTaskProperties(task)
      })
    );
  }

  changeMultiGCPType(task: Task, type: GCPType) {
    execute(this.changeMultiGCPType.name, () =>
      mixpanel.track('Change Multi GCP Type', {
        ...this.fetchTaskProperties(task),
        'GCP Type': type
      })
    );
  }

  removeGCP(task: Task, gcp: GCPItem) {
    execute(this.removeGCP.name, () =>
      mixpanel.track('Remove GCP', {
        ...this.fetchGCPProperties(gcp),
        ...this.fetchTaskProperties(task)
      })
    );
  }

  removeAllGCPs(task: Task) {
    execute(this.removeAllGCPs.name, () =>
      mixpanel.track('Remove All GCPs', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  private fetchGCPProperties(gcp: GCPItem) {
    if (!gcp) {
      return;
    }

    return {
      'GCP ID': gcp.id,
      'GCP Name': gcp.name,
      'GCP Type': gcp.type,
      'GCP E': gcp.x,
      'GCP N': gcp.y,
      'GCP H': gcp.z,
      'GCP Hints Count': gcp.hints?.length ?? 0,
      'GCP Marks Count': gcp.marks?.length ?? 0
    };
  }

  startCalculateAccuracy(task: Task) {
    execute(this.startCalculateAccuracy.name, () =>
      mixpanel.track('Start Calculate Accuracy', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  startAIMarking(task: Task) {
    execute(this.startAIMarking.name, () =>
      mixpanel.track('Start AI Marking', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  startRerunAI(task: Task) {
    execute(this.startRerunAI.name, () =>
      mixpanel.track('Start Rerun AI Marking', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  startAutoImproveProcess(task: Task) {
    execute(this.startAutoImproveProcess.name, () =>
      mixpanel.track('Start Improve & Revalidate', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  clickFlightWizardGcpMarkingContactSupport(
    task: Task,
    checkAccuracyCounter: number,
    autoImproveCounter: number,
    markedGCPsCount: number,
    tasksCount: number
  ) {
    execute(this.clickFlightWizardGcpMarkingContactSupport.name, () =>
      mixpanel.track('Click Flight Wizard GCP Marking Contact Support', {
        ...this.fetchTaskProperties(task),
        'Check Accuracy Counter': checkAccuracyCounter,
        'Improve & Revalidate Counter': autoImproveCounter,
        'Marked GCPs Count': markedGCPsCount,
        'Tasks Count': tasksCount
      })
    );
  }

  clickCancelInGenerateModelConfirmation(task: Task) {
    execute(this.clickCancelInGenerateModelConfirmation.name, () =>
      mixpanel.track('Click Cancel In Generate Model Confirmation Dialog', {
        ...this.fetchTaskProperties(task)
      })
    );
  }

  clickFlightWizardCoordinateSystemModifier(
    selectedOption: CoordinateSystemDialogOption,
    selectedCoordinateSystem?: CoordinateSystem,
    task?: Task
  ) {
    execute(this.clickFlightWizardCoordinateSystemModifier.name, () =>
      mixpanel.track('Submit Flight Wizard Coordinate System Modifier Dialog', {
        ...this.fetchTaskProperties(task),
        ...addPrefixToObjectKeys(this.fetchCoordinateSystemProperties(selectedCoordinateSystem), 'Selected '),
        'Selected Option': selectedOption
      })
    );
  }

  siteCustomPropertiesInteraction(site: Site, properties: DesignCustomProperty[], interactionType: CustomPropertyInteractionType) {
    execute(this.siteCustomPropertiesInteraction.name, () =>
      mixpanel.track('Site Custom Properties Interaction', {
        'Site ID': site.id,
        'Site Name': site.name,
        Interaction: interactionType,
        'Site 3D Custom Properties':
          properties.map(customProperty => `${customProperty.name} (${customProperty.valueType})`).join(', ') || ''
      })
    );
  }

  designCustomPropertyInteraction(design: Design, property: DesignLayerProperty, interactionType: CustomPropertyInteractionType) {
    execute(this.designCustomPropertyInteraction.name, () =>
      mixpanel.track('Design Custom Property Interaction', {
        ...this.fetchDesignProperties(design),
        Interaction: interactionType,
        'Property Name': property.name,
        'Property Type': property.valueType
      })
    );
  }

  designLayersFiltering(design: Design, filterString: string) {
    const designProperties =
      design.type === DesignType.DESIGN ? this.fetchDesignProperties(design) : this.fetchRoadDesignProperties(design);
    execute(this.designLayersFiltering.name, () =>
      mixpanel.track('Design Layers Filtering', {
        ...designProperties,
        'Filter String': filterString
      })
    );
  }
}
