/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.6.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetDesignUploadedByResponse } from './getDesignUploadedByResponse';

export interface DesignVersionModel {
  activeVersion?: boolean;
  creationTime?: Date;
  currentVersion?: boolean;
  externalId?: string;
  id?: string;
  name?: string;
  state?: DesignVersionModel.StateEnum;
  uploadedBy?: GetDesignUploadedByResponse;
}
export namespace DesignVersionModel {
  export type StateEnum = 'NOT_LOADED' | 'UPLOADING' | 'SYNCING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED' | 'DELETING';
  export const StateEnum = {
    NOTLOADED: 'NOT_LOADED' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    SYNCING: 'SYNCING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum,
    DELETING: 'DELETING' as StateEnum
  };
}
