import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { ShutterTypeEnum, Task } from '../../detailed-site/state/detailed-site.model';
import { UploadState } from '../../shared/services/s3-files-upload.service';
import { Cartographic } from '../../shared/utils/geo';
import { Site } from '../../tenant/tenant.model';
import { STEPS, STEPS_INFO, Step } from '../wizard-stepper/steps';
import {
  DEFAULT_IMAGE_MAX_RESOLUTION_PX,
  FileToUpload,
  ImageToApprove,
  MapHoveredNames,
  PerImagesValidationWarning,
  RTKStatus,
  TotalValidationWarning,
  UploadWizardMode,
  ValidationError,
  ValidationLimits
} from './upload-wizard.model';

const VALIDATION_LIMITS: ValidationLimits = Object.freeze({
  lowerDistanceMeter: 2,
  upperDistanceMeter: 60,
  absHeightDeltaMeter: 15,
  neighboursTimeDeltaHour: 1,
  totalTimeHour: 4,
  maxResolutionPx: DEFAULT_IMAGE_MAX_RESOLUTION_PX,
  maxTotalResolutionGpx: 180
});

export interface UploadWizardState {
  validation: {
    limits: ValidationLimits;
    countedTotalErrors: Partial<Record<ValidationError, number>>;
    countedTotalWarnings: Partial<Record<TotalValidationWarning, number>>;
  };
  hasAllValidRTKImages: boolean;
  shutterType: ShutterTypeEnum;
  avgFlightSpeed: number;
  uploadingLoading: boolean;
  uploadResult: UploadState;
  ui: {
    isFromBackup: boolean;
    mode: UploadWizardMode;
    step: Step;
    automodel: boolean;
    isFirstFlight: boolean;
    uploadingError: boolean;
  };
  boundingPolygon: Cartographic[];
  site: Site;
  taskId: string;
  task: Task;
  activeImageName: string;
  mapHoveredNames: MapHoveredNames;
}

export function createInitialState(): UploadWizardState {
  return {
    validation: {
      limits: { ...VALIDATION_LIMITS },
      countedTotalErrors: {},
      countedTotalWarnings: {}
    },
    hasAllValidRTKImages: null,
    shutterType: ShutterTypeEnum.UNKNOWN,
    avgFlightSpeed: null,
    uploadingLoading: false,
    uploadResult: undefined,
    ui: {
      isFromBackup: false,
      mode: UploadWizardMode.CREATE,
      step: Step.IMAGES,
      automodel: false,
      isFirstFlight: false,
      uploadingError: false
    },
    boundingPolygon: undefined,
    site: undefined,
    taskId: undefined,
    task: undefined,
    activeImageName: '',
    mapHoveredNames: {
      image: '',
      issue: '',
      noncoverageWarning: ''
    }
  };
}

export interface UploadFileState extends EntityState<FileToUpload> {}
export interface ApproveImageState extends EntityState<ImageToApprove> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'upload-wizard' })
export class UploadWizardStore extends Store<UploadWizardState> {
  uploadFiles = new EntityStore<UploadFileState>({}, { name: 'upload-wizard-files', idKey: 'name' });
  approveImages = new EntityStore<ApproveImageState>({}, { name: 'upload-wizard-images', idKey: 'name' });

  constructor() {
    super(createInitialState());
  }

  upsertFilesToUpload(files: FileToUpload[]) {
    this.uploadFiles.upsertMany(files);
  }

  upsertTaskImagesToApprove(images: ImageToApprove[]) {
    this.updateHasAllValidRTKImages(images?.some(image => image.rtkStatus === RTKStatus.GOOD));
    this.approveImages.upsertMany(images);
  }

  removeAllFileWarningsOfType(warning: PerImagesValidationWarning) {
    this.uploadFiles.update(null, entity => ({ ...entity, warnings: entity.warnings.filter(w => w !== warning) }));
  }

  removeAllImageWarningsOfType(warning: PerImagesValidationWarning) {
    this.approveImages.update(null, entity => ({ ...entity, warnings: entity.warnings.filter(w => w !== warning) }));
  }

  setFileErrorsByName(name: string, errors: ValidationError[]) {
    this.uploadFiles.update(name, { errors });
  }

  setFileWarningsByName(
    name: string,
    warnings: PerImagesValidationWarning[],
    nearestNoncoverageAreaCenter?: { longitude: number; latitude: number }
  ) {
    this.uploadFiles.update(name, { warnings, nearestNoncoverageAreaCenter });
  }

  setImageWarningsByName(
    name: string,
    warnings: PerImagesValidationWarning[],
    nearestNoncoverageAreaCenter?: { longitude: number; latitude: number }
  ) {
    this.approveImages.update(name, { warnings, nearestNoncoverageAreaCenter });
  }

  setImageNearestNoncoverageAreaCenterByName(name: string, nearestNoncoverageAreaCenter?: { longitude: number; latitude: number }) {
    this.approveImages.update(name, { nearestNoncoverageAreaCenter });
  }

  resetFiles() {
    // Must remove with explicit condition to get back removed IDs
    this.uploadFiles.remove((f: FileToUpload) => !!f.name);
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.validation.countedTotalErrors = {};
        draftState.validation.countedTotalWarnings = {};
      })
    );
  }

  deleteFileByName(name: string) {
    this.uploadFiles.remove(name);
  }

  deleteImageByName(name: string) {
    this.approveImages.remove(name);
  }

  updateActiveImageName(name: string) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.activeImageName = name;
      })
    );
  }

  updateMapHoveredNames({ image = '', issue = '', noncoverageWarning = '' } = {}) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.mapHoveredNames = {
          image,
          issue,
          noncoverageWarning
        };
      })
    );
  }

  updateActiveHoveredNames({ activeImage = '', image = '', issue = '', noncoverageWarning = '' } = {}) {
    this.updateActiveImageName(activeImage);
    this.updateMapHoveredNames({ image, issue, noncoverageWarning });
  }

  setCountedTotalErrors(countedTotalErrors: Partial<Record<ValidationError, number>>) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.validation.countedTotalErrors = countedTotalErrors;
      })
    );
  }

  setCountedTotalWarnings(countedTotalWarnings: Partial<Record<TotalValidationWarning, number>>) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.validation.countedTotalWarnings = countedTotalWarnings;
      })
    );
  }

  updateHasAllValidRTKImages(hasAllValidRTKImages: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.hasAllValidRTKImages = hasAllValidRTKImages;
      })
    );
  }

  updateValidationLimits(updatedLimits: Partial<ValidationLimits>) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.validation.limits = {
          ...draftState.validation.limits,
          ...updatedLimits
        };
      })
    );
  }

  setIsFromBackup(isFromBackup: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.isFromBackup = isFromBackup;
      })
    );
  }

  setMode(mode: UploadWizardMode) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.mode = mode;
      })
    );
  }

  setStep(step: Step) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.step = step;
      })
    );
  }

  nextStep() {
    this.update(
      produce((draftState: UploadWizardState) => {
        const visibleSteps = STEPS.filter(s => !draftState.ui.automodel || STEPS_INFO[s].showInAutomodel);
        const currentIndex = visibleSteps.findIndex(s => s === draftState.ui.step);
        if (currentIndex === visibleSteps.length - 1) {
          draftState.ui.step = visibleSteps[0];
        } else {
          draftState.ui.step = visibleSteps[currentIndex + 1];
        }
      })
    );
  }

  setFirstFlight(isFirstFlight: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.isFirstFlight = isFirstFlight;
      })
    );
  }

  setBoundingPolygon(positions: Cartographic[]) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.boundingPolygon = positions;
      })
    );
  }

  setUploadingError(hasError: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.uploadingError = hasError;
      })
    );
  }

  setUploadingLoading(loading: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.uploadingLoading = loading;
      })
    );
  }

  setUploadResult(uploadResult: UploadState) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.uploadResult = uploadResult;
      })
    );
  }

  resetUpload() {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.uploadingLoading = false;
        draftState.uploadResult = null;
      })
    );
  }

  setSite(site: Site) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.site = site;
      })
    );
  }

  setTaskId(taskId: string) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.taskId = taskId;
      })
    );
  }

  setTask(task: Task) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.task = task;
      })
    );

    this.updateAvgFlightSpeedAndShutterType(task?.avgFlightSpeed, task?.shutterType);
  }

  updateAvgFlightSpeedAndShutterType(avgFlightSpeed: number, shutterType: ShutterTypeEnum) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.avgFlightSpeed = avgFlightSpeed;
        draftState.shutterType = shutterType ?? ShutterTypeEnum.UNKNOWN;
      })
    );
  }

  setAutomodel(isAutomodel: boolean) {
    this.update(
      produce((draftState: UploadWizardState) => {
        draftState.ui.automodel = isAutomodel;
      })
    );
  }

  reset() {
    super.reset();
    this.uploadFiles.reset();
    this.approveImages.reset();
  }
}
