import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Papa, ParseError, ParseResult } from 'ngx-papaparse';
import { Observable, Subject, concat, defer, forkJoin, of, throwError } from 'rxjs';
import { filter, finalize, skip, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { GetAllImagesResponse } from '../../../../generated/file/model/getAllImagesResponse';
import { CheckAccuracyResponse } from '../../../../generated/fms/model/checkAccuracyResponse';
import { GetBatchResponse } from '../../../../generated/jms/model/getBatchResponse';
import { CreateGcpRequest } from '../../../../generated/mms/model/createGcpRequest';
import { CreateGcpsResponse } from '../../../../generated/mms/model/createGcpsResponse';
import { GcpBlockResponse } from '../../../../generated/mms/model/gcpBlockResponse';
import { GetAllGcpsResponse } from '../../../../generated/mms/model/getAllGcpsResponse';
import { GetAllHintsResponse } from '../../../../generated/mms/model/getAllHintsResponse';
import { HintResponse } from '../../../../generated/mms/model/hintResponse';
import { UpdateGcpRequest } from '../../../../generated/mms/model/updateGcpRequest';
import { UpdateGcpsRequest } from '../../../../generated/mms/model/updateGcpsRequest';
import { AccessLevelEnum, REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { MlGcpStateEnum, Task, TaskStateEnum } from '../../../detailed-site/state/detailed-site.model';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiPollingService } from '../../../shared/services/api-polling.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { MapStyle } from '../../../shared/utils/cesium-common';
import { cleanString } from '../../../shared/utils/file-utils';
import { isDefined } from '../../../shared/utils/general';
import { GeoUtils } from '../../../shared/utils/geo';
import { convertToMeters } from '../../../shared/utils/unit-conversion';
import { UploadWizardQuery } from '../../state/upload-wizard.query';
import { GCPItem, GCPType, GCPsMovingSettings, GCPsOffset, Hint, Mark } from './gcp.model';
import { GcpQuery } from './gcp.query';
import { GcpProcessState, GcpStore, LoadingModalTypeEnum } from './gcp.store';

export function calcGCPEstimationResidualsAndDiff(gcp: GcpBlockResponse) {
  const invalidEstimation =
    ('estimatedX' in gcp && gcp.estimatedX === 0) ||
    ('estimatedY' in gcp && gcp.estimatedY === 0) ||
    ('estimatedZ' in gcp && gcp.estimatedZ === 0);
  const res = invalidEstimation ? [] : [gcp.x - gcp.estimatedX, gcp.y - gcp.estimatedY, gcp.z - gcp.estimatedZ];
  const diff = invalidEstimation ? null : Math.sqrt(res.map(n => n ** 2).reduce((sum, n) => sum + n));

  return { res, diff };
}

@Injectable({ providedIn: 'root' })
export class GcpService {
  constructor(
    private gcpQuery: GcpQuery,
    private gcpStore: GcpStore,
    private papa: Papa,
    private apiPoller: ApiPollingService,
    private http: HttpClient,
    private wizardQuery: UploadWizardQuery,
    private snackbar: SnackBarService,
    private analytics: AnalyticsService
  ) {}

  resetStore() {
    this.gcpStore.reset();
  }

  updateServerGCPsAndOffsets() {
    const gcps: UpdateGcpRequest[] = this.gcpQuery.getAllGCPs().map(gcp => ({
      id: gcp.id,
      name: gcp.name,
      gcpType: gcp.type,
      marks: Object.keys(gcp.marks).map(imageId => ({
        imageId,
        ml: false,
        ...gcp.marks[imageId]
      })),
      x: gcp.x,
      y: gcp.y,
      z: gcp.z
    }));
    const { longitude, latitude, degree } = this.gcpQuery.getGCPsOffset();
    const body: UpdateGcpsRequest = {
      gcps,
      coordinateSystem: 'TODO',
      manualLonOffset: longitude,
      manualLatOffset: latitude,
      manualRotationDegree: degree
    };
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps`, body, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.update }
    });
  }

  updateServerGCPOffsets() {
    const { longitude, latitude, degree } = this.gcpQuery.getGCPsOffset();
    const body: UpdateGcpsRequest = {
      gcps: [],
      coordinateSystem: 'TODO',
      manualLonOffset: longitude,
      manualLatOffset: latitude,
      manualRotationDegree: degree
    };
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps`, body, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.update }
    });
  }

  private updateGCPMarks(gcp: GCPItem, marks: Record<string, Mark>) {
    this.gcpStore.gcps.update(gcp.id, { marks });

    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    const body: UpdateGcpRequest = {
      id: gcp.id,
      gcpType: gcp.type,
      name: gcp.name,
      x: gcp.x,
      y: gcp.y,
      z: gcp.z,
      marks: Object.keys(marks).map((imageId: string) => ({
        imageId,
        ml: false,
        ...marks[imageId]
      }))
    };
    return this.http
      .put<void>(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps/${gcp.id}`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.update }
      })
      .pipe(tap(() => this.setIsGCPsDirty(true)));
  }

  saveGCPMarks(gcp: GCPItem, marks: Record<string, Mark>) {
    const task = this.wizardQuery.getTask();
    this.analytics.saveGCPMarks(task, { ...gcp, marks });

    return this.updateGCPMarks(gcp, marks);
  }

  removeGCPMarks(gcpId: string) {
    const gcp = this.gcpQuery.getGCP(gcpId);

    const task = this.wizardQuery.getTask();
    this.analytics.removeGCPMarks(task, { ...gcp, marks: {} });

    return this.updateGCPMarks(gcp, {});
  }

  parse(file: File) {
    return new Promise<CreateGcpRequest[]>((resolve, reject) => {
      this.papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => cleanString(header).toLocaleLowerCase(),
        transform: (value: string) => cleanString(value),
        complete: (result: ParseResult) => {
          // If there's a parse error, show first one
          if (result.errors.length > 0) {
            const firstError: ParseError = result.errors[0];

            const errorMessage = firstError.message;
            const rowNumber = firstError.row;

            reject({
              message: $localize`:@@detailedSite.reportDialogSharedText.errorInRow:${errorMessage} in row ${rowNumber}`
            });
            return;
          }

          // If there's no data, means there are no rows
          if (!isDefined(result.data)) {
            reject({ message: $localize`:@@shared.csvParsing.errorMissingFields:Missing fields` });
            return;
          }

          const gcps: CreateGcpRequest[] = result.data
            .map((line: any) => ({
              name: line.name,
              x: +(line.e || line.x),
              y: +(line.n || line.y),
              z: +(line.h || line.z)
            }))
            .filter((gcp: CreateGcpRequest) => gcp.name && gcp.x && gcp.y && gcp.z);

          // If there are no GCPs in file, it must be missing fields
          if (gcps.length === 0) {
            reject({ message: $localize`:@@shared.csvParsing.errorMissingFields:Missing fields` });
          } else {
            resolve(gcps);
          }
        }
      });
    });
  }

  saveGCPs(gcps: CreateGcpRequest[]) {
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();

    const body = { coordinateSystem: 'TODO', gcps };
    return this.http
      .post(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.create }
      })
      .pipe(
        switchMap((response: CreateGcpsResponse) => {
          this.addGCPsToStore(response.gcps);

          let observable$: Observable<any> = of(null);
          if (response.batchId) {
            observable$ = concat(
              defer(() => {
                this.setLoadingModalType(LoadingModalTypeEnum.GENERATING_HINTS);
                return of(null);
              }),
              this.pollHintGeneration(response.batchId)
            );
          }

          return observable$.pipe(finalize(() => this.setLoadingModalType(null)));
        })
      );
  }

  private pollHintGeneration(batchId: string) {
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    const batchCompletedSubject = new Subject<void>();

    return this.pollBatch(batchId, PERMISSIONS.gcps.create).pipe(
      takeUntil(batchCompletedSubject),
      switchMap((response: GetBatchResponse) => {
        if (response.state === GetBatchResponse.StateEnum.FAILED) {
          const msg = 'Error generating hints';
          console.error(msg, response);
          return throwError(() => msg);
        } else if (response.state === GetBatchResponse.StateEnum.READY) {
          return this.fetchGCPHints(siteId, taskId).pipe(
            finalize(() => {
              batchCompletedSubject.next();
            })
          );
        }
      })
    );
  }

  pollMLGcpMarking() {
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    const completedSubject = new Subject<void>();

    return this.wizardQuery.task$.pipe(
      skip(1), // Skip first task state since it didn't change in store yet
      takeUntil(completedSubject),
      // Stop polling if wizard was closed
      takeWhile(() => this.wizardQuery.getSiteId() === siteId && this.wizardQuery.getTaskId() === taskId),
      switchMap((task: Task) => {
        if (
          [TaskStateEnum.MLGCPINPROGRESS, TaskStateEnum.SBAINPROGRESS].includes(task.state) ||
          task.mlgcpState === MlGcpStateEnum.NOTSTARTED
        ) {
          // Skip and continue polling until ML finishes
          return of(null);
        } else if (task.mlgcpState === MlGcpStateEnum.FAILED) {
          const msg = 'Error in AI GCP marking';
          console.error(msg);
          return throwError(() => msg);
        } else if (task.state === TaskStateEnum.WAITINGFORGCP && task.mlgcpState === MlGcpStateEnum.READY) {
          return concat(this.fetchGCPs(siteId, taskId), this.fetchGCPHints(siteId, taskId)).pipe(
            finalize(() => {
              completedSubject.next();
            })
          );
        }
      })
    );
  }

  removeAllGCPs() {
    const siteId = this.wizardQuery.getSiteId();
    const task = this.wizardQuery.getTask();

    this.analytics.removeAllGCPs(task);

    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${task.id}/gcps`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.delete }
      })
      .pipe(
        tap(() => {
          this.gcpStore.gcps.remove();
          this.setGCPsOffset();
          this.setIsGCPsDirty(false);
          this.resetCheckAccuracyCounter();
          this.gcpStore.setProcessState(GcpProcessState.MARKING);
        })
      );
  }

  removeGCP(id: string) {
    const isGCPMarked = this.gcpQuery.getIsGCPMarked(id);

    const siteId = this.wizardQuery.getSiteId();
    const task = this.wizardQuery.getTask();

    this.analytics.removeGCP(task, this.gcpQuery.getGCP(id));

    if (this.gcpQuery.getIsMarkModalOpen()) {
      // Current GCP is deleted - change active to next one
      const nextGCPId = this.gcpQuery.getNextGCPIdByTableSorting(id);
      this.setMapActiveGcpId(nextGCPId);
    }

    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${task.id}/gcps/${id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.delete }
      })
      .pipe(
        tap(() => {
          this.gcpStore.gcps.remove(id);

          if (isGCPMarked) {
            this.setIsGCPsDirty(true);
          }
        })
      );
  }

  updateGCPsLonLatInStore(offset: GCPsOffset) {
    const gcps = this.gcpQuery.getAllGCPs();
    const rotationPivot = GeoUtils.pointCenterByLonLat(
      gcps.map(gcp => ({
        longitude: gcp.longitudeOri,
        latitude: gcp.latitudeOri
      }))
    );
    gcps.forEach(gcp => {
      const rotatedLonLat = GeoUtils.rotatePositionAroundPivot(gcp.longitudeOri, gcp.latitudeOri, offset.degree, rotationPivot);
      this.gcpStore.gcps.update(gcp.id, {
        longitude: rotatedLonLat.longitude - offset.longitude,
        latitude: rotatedLonLat.latitude - offset.latitude
      });
    });
  }

  updateGCPsMovingSettings(settings: GCPsMovingSettings) {
    this.gcpStore.setGcpsMovingSettings(settings);
  }

  updateGCPTypes(ids: string[], type: GCPType) {
    this.gcpStore.gcps.update(ids, { type });
    this.setIsGCPsDirty(true);

    const task = this.wizardQuery.getTask();
    if (ids?.length === 1) {
      const gcp = this.gcpQuery.getGCP(ids[0]);
      this.analytics.changeGCPType(task, gcp);
    } else {
      this.analytics.changeMultiGCPType(task, type);
    }

    return this.updateServerGCPsAndOffsets();
  }

  setLoadingModalType(type: LoadingModalTypeEnum) {
    this.gcpStore.setLoadingModalType(type);
  }

  setLoading(loading: boolean) {
    this.gcpStore.setLoading(loading);
  }

  fetchInitialData() {
    this.setLoading(true);
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();
    return forkJoin([this.getImages(taskId), concat(this.fetchGCPs(siteId, taskId), this.fetchGCPHints(siteId, taskId))]).pipe(
      finalize(() => {
        this.setLoading(false);
      })
    );
  }

  private checkCalculatedAccuracy(gcps: GcpBlockResponse[]) {
    if (gcps && gcps.some(gcp => !!gcp.estimatedX || !!gcp.estimatedY || !!gcp.estimatedZ)) {
      this.gcpStore.setProcessState(GcpProcessState.RESIDUALS);
    }
  }

  getImages(taskId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/images?taskId=${taskId}&isLinked=true`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.images.read }
      })
      .pipe(tap((response: GetAllImagesResponse) => this.gcpStore.setImages(response.images)));
  }

  fetchGCPs(siteId: string, taskId: string): Observable<GetAllGcpsResponse> {
    return this.fetchGCPsWithoutAddToStore(siteId, taskId).pipe(
      tap((response: GetAllGcpsResponse) => {
        this.setIsGCPsDirty(response?.dirty);
        this.setProcessCounters(response?.checkAccuracyCounter ?? 0, response?.fixAndRunAccuracyCheckCounter ?? 0);
        this.setLastUpdatedByUserId(response?.lastUpdatedByUserId);

        if (isDefined(response?.gcps)) {
          this.addGCPsToStore(response.gcps, {
            longitude: response.manualLonOffset,
            latitude: response.manualLatOffset,
            degree: response.manualRotationDegree
          });
          this.setGCPsOffset({
            longitude: response.manualLonOffset,
            latitude: response.manualLatOffset,
            degree: response.manualRotationDegree
          });

          this.checkCalculatedAccuracy(response.gcps);
        }
      })
    );
  }

  fetchGCPsWithoutAddToStore(siteId: string, taskId: string): Observable<GetAllGcpsResponse> {
    const url = `${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps`;
    return this.http.get(url, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.read }
    });
  }

  setGCPsOffset(gcpsOffset: GCPsOffset = { longitude: 0, latitude: 0, degree: 0 }) {
    this.gcpStore.setGCPsOffset(gcpsOffset);
  }

  setIsGCPsDirty(isDirty: boolean) {
    this.gcpStore.setIsGCPsDirty(isDirty);
  }

  setProcessCounters(checkAccuracyCounter = 0, autoImproveCounter = 0) {
    this.gcpStore.setProcessCounters(checkAccuracyCounter, autoImproveCounter);
  }

  setLastUpdatedByUserId(lastUpdatedByUserId: string) {
    this.gcpStore.setLastUpdatedByUserId(lastUpdatedByUserId);
  }

  resetCheckAccuracyCounter() {
    this.gcpStore.resetCheckAccuracyCounter();
  }

  calculateGCPsImagesOverlapping() {
    const gcps = this.gcpQuery.getAllGCPs();
    const images = this.gcpQuery.getAllImages();
    if (isDefined(gcps) && isDefined(images)) {
      const gcpsLonLat = this.gcpQuery.getGCPsLonLat();
      const imagesLonLat = this.gcpQuery.getImagesLonLat();
      const isOverlapping = GeoUtils.isOverlapping(gcpsLonLat, imagesLonLat);

      this.gcpStore.setGCPsImagesOverlapping(isOverlapping);
    }
  }

  private addGCPsToStore(gcpResponses: GcpBlockResponse[], gcpsOffset?: GCPsOffset) {
    if (!gcpResponses) {
      return;
    }

    const siteUnits = this.wizardQuery.getSite()?.units;

    let rotationPivot = null;
    if (gcpResponses.length > 0) {
      rotationPivot = GeoUtils.pointCenterByLonLat(
        gcpResponses.map(response => ({
          longitude: response.longitude,
          latitude: response.latitude
        }))
      );
    }

    gcpsOffset = gcpsOffset || this.gcpQuery.getGCPsOffset();
    const gcps = gcpResponses.map(gcpResponse => {
      let rotatedLonLat = { longitude: gcpResponse.longitude, latitude: gcpResponse.latitude };
      let marks = {};
      if (gcpResponse.marks) {
        marks = gcpResponse.marks.reduce((sum, mark: Mark) => {
          sum[mark.imageId] = mark;
          return sum;
        }, {} as Record<string, Mark>);
      }
      if (gcpResponses.length > 0) {
        rotatedLonLat = GeoUtils.rotatePositionAroundPivot(gcpResponse.longitude, gcpResponse.latitude, gcpsOffset.degree, rotationPivot);
      }

      const hints = this.gcpQuery.getGCP(gcpResponse.id)?.hints || {};
      const { res, diff } = calcGCPEstimationResidualsAndDiff(gcpResponse);
      const result: GCPItem = {
        id: gcpResponse.id,
        name: gcpResponse.name,
        type: gcpResponse.gcpType || 'GCP',
        x: gcpResponse.x,
        y: gcpResponse.y,
        z: gcpResponse.z,
        estimatedX: gcpResponse.estimatedX,
        estimatedY: gcpResponse.estimatedY,
        estimatedZ: gcpResponse.estimatedZ,
        longitude: rotatedLonLat.longitude - gcpsOffset.longitude,
        latitude: rotatedLonLat.latitude - gcpsOffset.latitude,
        longitudeOri: gcpResponse.longitude,
        latitudeOri: gcpResponse.latitude,
        altitude: convertToMeters(gcpResponse.z, siteUnits),
        marks,
        res,
        diff,
        hints
      };

      return result;
    });

    this.gcpStore.gcps.upsertMany(gcps, { loading: true });
  }

  private fetchGCPHints(siteId: string, taskId: string, showNoHintsWarning = false) {
    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/hints`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.hints.read }
      })
      .pipe(
        tap((result: GetAllHintsResponse) => {
          if (result && result.hints && result.hints.length > 0) {
            this.saveGCPHints(result.hints);
          } else if (showNoHintsWarning) {
            this.snackbar.open('No hints were generated');
          }
        })
      );
  }

  private saveGCPHints(hints: HintResponse[]) {
    // Create mapping gcpId => imageId => hintInfo for less store updates
    const hintsMap = hints.reduce((sum, hint: HintResponse) => {
      const { gcpId, imageId } = hint;
      if (!(gcpId in sum)) {
        sum[gcpId] = {};
      }
      sum[gcpId][imageId] = hint;
      return sum;
    }, {} as Record<string, Record<string, Hint>>);

    // Update store with hints for each GCP item
    for (const gcpId of Object.keys(hintsMap)) {
      this.gcpStore.gcps.update(gcpId, (gcpItem: GCPItem) => ({
        hints: {
          ...gcpItem.hints,
          ...hintsMap[gcpId]
        }
      }));
    }
  }

  runMlMarkingProcess() {
    const task = this.wizardQuery.getTask();
    this.analytics.startAIMarking(task);

    this.setLoadingModalType(LoadingModalTypeEnum.AI_MARKING);

    return this.http
      .post(`${getServiceUrl('fms')}/tasks/${task.id}/runMLGcp`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.runAIGCPMarking }
      })
      .pipe(
        switchMap(() => this.pollMLGcpMarking()),
        finalize(() => {
          this.setLoading(false);
          this.setLoadingModalType(null);
        })
      );
  }

  rerunMlMarking() {
    const task = this.wizardQuery.getTask();
    this.analytics.startRerunAI(task);

    this.setLoadingModalType(LoadingModalTypeEnum.AI_MARKING);

    return this.http
      .post(`${getServiceUrl('fms')}/tasks/${task.id}/runMLGcpWithoutHints`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.runAIGCPMarking }
      })
      .pipe(
        switchMap(() => this.pollMLGcpMarking()),
        finalize(() => {
          this.setLoading(false);
          this.setLoadingModalType(null);
        })
      );
  }

  calculateAccuracy() {
    const task = this.wizardQuery.getTask();
    this.analytics.startCalculateAccuracy(task);

    this.setLoadingModalType(LoadingModalTypeEnum.CALCULATING_ACCURACY);

    return this.http
      .post(`${getServiceUrl('fms')}/tasks/${task.id}/checkAccuracy`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.checkAccuracy }
      })
      .pipe(
        switchMap((response: CheckAccuracyResponse) => {
          let observable$: Observable<any> = this.pollCheckAccuracy(response.batchId);
          if (
            this.gcpQuery.getIsLocalCS() &&
            this.wizardQuery.getIsML() &&
            this.wizardQuery.getTask().mlgcpState === MlGcpStateEnum.NOTSTARTED
          ) {
            observable$ = concat(
              observable$,
              defer(() => {
                this.setLoadingModalType(LoadingModalTypeEnum.AI_MARKING);
                return of(null);
              }),
              this.pollMLGcpMarking()
            );
          }

          return observable$;
        }),
        finalize(() => {
          this.setLoading(false);
          this.setLoadingModalType(null);
        })
      );
  }

  private pollCheckAccuracy(batchId: string, isAutoImprove = false) {
    const batchCompletedSubject = new Subject<void>();

    return this.pollBatch(batchId, isAutoImprove ? PERMISSIONS.autoImprove : PERMISSIONS.checkAccuracy).pipe(
      takeUntil(batchCompletedSubject),
      switchMap((response: GetBatchResponse) => {
        if (response.state === GetBatchResponse.StateEnum.FAILED) {
          const msg = isAutoImprove ? 'Error in improve & calculate accuracy batch' : 'Error in check accuracy batch';
          console.error(msg, response);
          return throwError(() => msg);
        } else if (response.state === GetBatchResponse.StateEnum.READY) {
          const siteId = this.wizardQuery.getSiteId();
          const taskId = this.wizardQuery.getTaskId();
          return concat(this.fetchGCPs(siteId, taskId), this.fetchGCPHints(siteId, taskId, true)).pipe(
            finalize(() => {
              this.gcpStore.setProcessState(GcpProcessState.RESIDUALS);
              batchCompletedSubject.next();
            })
          );
        }
      })
    );
  }

  private pollBatch(batchId: string, permission: AccessLevelEnum, pollingFrequency = 10 * 1000) {
    const siteId = this.wizardQuery.getSiteId();
    const taskId = this.wizardQuery.getTaskId();

    return this.apiPoller.poll(`${getServiceUrl('jms')}/sites/${siteId}/batches/${batchId}`, permission, pollingFrequency).pipe(
      // Stop polling if wizard was closed
      takeWhile(() => this.wizardQuery.getSiteId() === siteId && this.wizardQuery.getTaskId() === taskId),
      filter((response: GetBatchResponse) => response.state !== GetBatchResponse.StateEnum.PROCESSING)
    );
  }

  runAutoImproveProcess() {
    const task = this.wizardQuery.getTask();
    this.analytics.startAutoImproveProcess(task);

    this.setLoadingModalType(LoadingModalTypeEnum.AUTO_IMPROVE);

    return this.http
      .post(`${getServiceUrl('fms')}/tasks/${task.id}/fixAndRunCheckAccuracy`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.autoImprove }
      })
      .pipe(
        switchMap((response: any) => {
          return this.pollCheckAccuracy(response.batchId, true);
        }),
        finalize(() => {
          this.setLoading(false);
          this.setLoadingModalType(null);
        })
      );
  }

  setMapActiveGcpId(id: string) {
    this.gcpStore.setMapActiveGcpId(id);
  }

  setGcpTableSorting(sorting: Sort) {
    this.gcpStore.setGcpTableSorting(sorting);
  }

  toggleShowImages(show: boolean) {
    this.gcpStore.toggleShowImages(show);
  }

  toggleShowGcpNames(show: boolean) {
    this.gcpStore.toggleShowGcpName(show);
  }

  toggleShowImageNames(show: boolean) {
    this.gcpStore.toggleShowImageName(show);
  }

  toggleShowGcpWithMarking(show: boolean) {
    this.gcpStore.toggleShowGcpWithMarking(show);
  }

  toggleShowGcpWithoutMarking(show: boolean) {
    this.gcpStore.toggleShowGcpWithoutMarking(show);
  }

  toggleShowCheckpointsWithMarking(show: boolean) {
    this.gcpStore.toggleShowCheckpointsWithMarking(show);
  }

  toggleShowCheckpointsWithoutMarking(show: boolean) {
    this.gcpStore.toggleShowCheckpointsWithoutMarking(show);
  }

  toggleShowHintCount(show: boolean) {
    this.gcpStore.toggleShowHintCount(show);
  }

  setMapOrthoTaskId(taskId: string) {
    this.gcpStore.setMapOrthoTaskId(taskId);
  }

  setMapStyle(mapStyle: MapStyle) {
    this.gcpStore.setMapStyle(mapStyle);
  }

  setIsMarkModalOpen(isMarkModalOpen: boolean) {
    this.gcpStore.setIsMarkModalOpen(isMarkModalOpen);
  }
}
