import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import {
  CrossSectionProgressReport,
  CrossSectionVolumeReport,
  ElevationGridHeatmapReport,
  ReportEntity,
  ReportType,
  RoadGradeCheckingReport,
  SurfaceGradeCheckingReport,
  VolumeGridHeatmapReport,
  WaterFlowReport
} from './detailed-site-reports.model';

export interface DetailedSiteReportsState {}

export function createInitialState(): DetailedSiteReportsState {
  return {};
}

interface CrossSectionVolumeReportsState extends EntityState<CrossSectionVolumeReport> {}
interface CrossSectionProgressReportsState extends EntityState<CrossSectionProgressReport> {}
interface GridReportsState extends EntityState<VolumeGridHeatmapReport | ElevationGridHeatmapReport> {}
interface SurfaceGradeCheckingReportsState extends EntityState<SurfaceGradeCheckingReport> {}
interface RoadGradeCheckingReportsState extends EntityState<RoadGradeCheckingReport> {}
interface WaterFlowReportState extends EntityState<WaterFlowReport> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-reports' })
export class DetailedSiteReportsStore extends Store<DetailedSiteReportsState> {
  crossSectionVolumeReports = new EntityStore<CrossSectionVolumeReportsState>({}, { name: 'cross-section-volume-reports', idKey: 'id' });
  crossSectionProgressReports = new EntityStore<CrossSectionProgressReportsState>(
    {},
    { name: 'cross-section-progress-reports', idKey: 'id' }
  );
  gridReports = new EntityStore<GridReportsState>({}, { name: 'grid-reports', idKey: 'id' });
  surfaceGradeCheckingReports = new EntityStore<SurfaceGradeCheckingReportsState>(
    {},
    { name: 'surface-grade-checking-reports', idKey: 'id' }
  );
  roadGradeCheckingReports = new EntityStore<RoadGradeCheckingReportsState>({}, { name: 'road-grade-checking-reports', idKey: 'id' });
  waterFlowReports = new EntityStore<WaterFlowReportState>({}, { name: 'water-flow-reports', idKey: 'id' });

  constructor() {
    super(createInitialState());
  }

  resetStore() {
    this.reset();
    this.crossSectionVolumeReports.reset();
    this.crossSectionProgressReports.reset();
    this.gridReports.reset();
    this.surfaceGradeCheckingReports.reset();
    this.roadGradeCheckingReports.reset();
    this.waterFlowReports.reset();
  }

  upsertCrossSectionVolumeReports(reports: CrossSectionVolumeReport[]) {
    this.crossSectionVolumeReports.upsertMany(reports);
  }

  upsertCrossSectionProgressReports(reports: CrossSectionProgressReport[]) {
    this.crossSectionProgressReports.upsertMany(reports);
  }

  upsertSurfaceGradeCheckingReports(reports: SurfaceGradeCheckingReport[]) {
    this.surfaceGradeCheckingReports.upsertMany(reports);
  }

  upsertRoadGradeCheckingReports(reports: RoadGradeCheckingReport[]) {
    this.roadGradeCheckingReports.upsertMany(reports);
  }

  upsertGridReports(reports: (VolumeGridHeatmapReport | ElevationGridHeatmapReport)[]) {
    this.gridReports.upsertMany(reports);
  }

  upsertWaterFlowReports(reports: WaterFlowReport[]) {
    this.waterFlowReports.upsertMany(reports);
  }

  deleteReport(report: ReportEntity) {
    const type = report.type;

    switch (type) {
      case ReportType.CROSS_SECTION_VOLUME:
        this.crossSectionVolumeReports.remove(report.id);
        break;
      case ReportType.CROSS_SECTION_PROGRESS:
        this.crossSectionProgressReports.remove(report.id);
        break;
      case ReportType.VOLUME_GRID_HEATMAP:
      case ReportType.ELEVATION_GRID_HEATMAP:
        this.gridReports.remove(report.id);
        break;
      case ReportType.SURFACE_GRADE_CHECKING:
        this.surfaceGradeCheckingReports.remove(report.id);
        break;
      case ReportType.ROAD_GRADE_CHECKING:
        this.roadGradeCheckingReports.remove(report.id);
        break;
      case ReportType.WATER_FLOW:
        this.waterFlowReports.remove(report.id);
        break;
    }
  }
}
