import { GetImageResponse } from '../../../../generated/file/model/getImageResponse';
import { GcpBlockResponse } from '../../../../generated/mms/model/gcpBlockResponse';
import { HintResponse } from '../../../../generated/mms/model/hintResponse';
import { MarkBlockModel } from '../../../../generated/mms/model/markBlockModel';

export const MIN_IMAGE_MARKS = 2;
export const MIN_IMAGE_MARKS_LOCAL_CS = 3;
export const MIN_MARKED_GCP = 3;

export const PIXEL_ACCURACY_ERROR_THRESHOLD = 10;
export const METER_ACCURACY_ERROR_THRESHOLD = 1;

export const RESIDUALS_BAD_X_Y_CUTOFF_GCP = 0.045;
export const RESIDUALS_BAD_X_Y_CUTOFF_MONUMENT = 0.045;
export const RESIDUALS_BAD_Z_CUTOFF_GCP = 0.065;
export const RESIDUALS_BAD_Z_CUTOFF_MONUMENT = 0.075;

export const DEFAULT_GCPS_MOVING_SETTINGS_METER: GCPsMovingSettings = {
  majorStep: 50,
  minorStep: 5,
  rotation: 10
};

export const DEFAULT_GCPS_MOVING_SETTINGS_FEET: GCPsMovingSettings = {
  majorStep: 150,
  minorStep: 15,
  rotation: 10
};

export const GCP_MAP_ID = 'gcp-map';

export type Image = GetImageResponse;

export import GCPType = GcpBlockResponse.GcpTypeEnum;

export type Mark = MarkBlockModel;
export type Hint = HintResponse;

export interface GCPItem {
  id: string;
  name: string;
  type: GCPType;
  x: number;
  y: number;
  z: number;
  estimatedX: number;
  estimatedY: number;
  estimatedZ: number;
  longitude: number;
  latitude: number;
  longitudeOri: number;
  latitudeOri: number;
  altitude: number;
  marks: Record<string, Mark>;
  hints: Record<string, Hint>;
  res: number[];
  diff: number;
}

export interface GCPsOffset {
  longitude: number;
  latitude: number;
  degree: number;
}

export interface GCPsMovingSettings {
  majorStep: number;
  minorStep: number;
  rotation: number;
}
