import { Cesium3DTileFeature } from 'cesium';
import { DesignErrorModel } from '../../../../generated/file/model/designErrorModel';
import { DesignVersionModel } from '../../../../generated/file/model/designVersionModel';
import { GetCategoryResponse } from '../../../../generated/file/model/getCategoryResponse';
import { GetCustomFieldResponse } from '../../../../generated/file/model/getCustomFieldResponse';
import { GetDesignResponse } from '../../../../generated/file/model/getDesignResponse';
import { GetFeatureDataResponse } from '../../../../generated/file/model/getFeatureDataResponse';
import { GetRoadDesignResponse } from '../../../../generated/file/model/getRoadDesignResponse';
import { RoadDesignErrorModel } from '../../../../generated/file/model/roadDesignErrorModel';
import { GetFileResponse } from '../../../../generated/integration/model/getFileResponse';
import { GetFolderResponse } from '../../../../generated/integration/model/getFolderResponse';
import { isDefined } from '../../../shared/utils/general';
import { DesignLayerFlatNode } from './designs-map-manager.service';

export type DesignCategory = GetCategoryResponse;

export type DesignType = 'DESIGN' | 'ROAD_DESIGN';
export const DesignType = {
  DESIGN: 'DESIGN' as DesignType,
  ROAD_DESIGN: 'ROAD_DESIGN' as DesignType
};

export interface Design extends GetDesignResponse {
  type: DesignType;
  allIsShown?: boolean;
  layers?: DesignLayerFlatNode[];
  bbox?: [number, number, number, number];
  loading?: boolean;
  loadingError?: boolean;
  isGeoJsonEntitiesMode?: boolean;
  inTempCategory?: boolean;
}

export interface RoadDesign extends GetRoadDesignResponse {
  type: DesignType;
  allIsShown?: boolean;
  layers?: DesignLayerFlatNode[];
  bbox?: [number, number, number, number];
  loading?: boolean;
  loadingError?: boolean;
  isGeoJsonEntitiesMode?: boolean;

  // Not in use, here for ease of use with Design | RoadDesign types
  terrainReady?: boolean;
  cesium3DReady?: boolean;
  hasBorder?: boolean;
  hasSurface?: boolean;
}

export type DesignVersion = DesignVersionModel;

export import RoadDesignType = GetRoadDesignResponse.RoadDesignTypeEnum;

export import DesignState = GetDesignResponse.StateEnum;
export import DesignVersionState = DesignVersionModel.StateEnum;

export const DESIGN_STATE_NAMES = {
  [DesignState.FAILED]: $localize`:@@detailedSite.designState.error:Error`,
  [DesignState.READY]: $localize`:@@detailedSite.designState.processed:Processed`,
  [DesignState.PROCESSING]: $localize`:@@detailedSite.designState.processing:Processing...`,
  [DesignState.UPLOADING]: $localize`:@@detailedSite.designState.uploading:Uploading...`,
  [DesignState.SYNCING]: $localize`:@@detailedSite.designState.syncing:Syncing...`,
  [DesignState.VALIDATIONFAILED]: ''
};

export import StationNamingFormat = GetRoadDesignResponse.StationNamingFormatTypeEnum;

export interface DesignsUploadingState {
  totalFiles: number;
  uploadedFiles: number;
  totalSize: number;
  uploadedSize: number;
  validating?: boolean;
}

export type DesignError = DesignErrorModel & RoadDesignErrorModel;
export const DesignErrorType = { ...DesignErrorModel.TypeEnum, ...RoadDesignErrorModel.TypeEnum };
export type DesignErrorType = typeof DesignErrorType;

export class RoadDesignValidationError extends Error {
  errors: DesignError[];
  roadDesign: RoadDesign;

  constructor(roadDesign: RoadDesign) {
    const message = roadDesign.errors.map(result => `${result.type} ${result.code}: ${result.description}`).join('\n');
    super(message);

    this.roadDesign = roadDesign;
    this.errors = roadDesign.errors;
  }
}

export interface DesignLayerProperties {
  [key: string]: string;
}

export interface DesignActiveLayer {
  designId: string;
  layerId: string;
  layerName: string;
  featureIds: Set<string>;
  properties?: DesignLayerProperty[];
}

export import DesignCustomProperty = GetCustomFieldResponse;
export import DesignLayerProperty = GetFeatureDataResponse;
export import DesignLayerPropertyType = GetFeatureDataResponse.FieldTypeEnum;
export import DesignLayerPropertyValueType = GetCustomFieldResponse.ValueTypeEnum;

export const PROPERTY_VALUE_TYPE_TRANSLATION = {
  [DesignLayerPropertyValueType.STRING]: $localize`:@@detailedSite.detailedSiteDesignModel.propertyValueTypeString:Text`,
  [DesignLayerPropertyValueType.DOUBLE]: $localize`:@@detailedSite.detailedSiteDesignModel.propertyValueTypeDouble:Number`,
  [DesignLayerPropertyValueType.DATE]: $localize`:@@detailedSite.detailedSiteDesignModel.propertyValueTypeDate:Date`,
  [DesignLayerPropertyValueType.BOOLEAN]: $localize`:@@detailedSite.detailedSiteDesignModel.propertyValueTypeBoolean:True/False`
};

export const customFieldToLayerProperty = (customFields: GetCustomFieldResponse[]): DesignLayerProperty[] => {
  if (!isDefined(customFields)) {
    return [];
  }

  return customFields.map(customField => ({
    featureId: null,
    fieldId: customField.id,
    fieldType: DesignLayerPropertyType.CUSTOM,
    id: null,
    name: customField.name,
    value: null,
    valueType: customField.valueType
  }));
};

export enum CustomPropertyInteractionType {
  CREATE = 'Create',
  UPDATE = 'Update'
}

export enum IntegrationDesignNodeType {
  FILE = 'file',
  FOLDER = 'folder'
}

export const getDesignFeatureId = (feature: Cesium3DTileFeature): string => {
  return feature.getProperty('GlobalId') || feature.getProperty('Element Specific.GlobalId');
};

export import IntegrationDesignType = GetFileResponse.DesignTypeEnum;

export interface IntegrationDesignNode extends GetFileResponse, GetFolderResponse {
  name?: string;
  type?: IntegrationDesignNodeType;
  state?: DesignState; // file. State of current version (current on the 3rd party)
  errors?: DesignErrorModel[]; // file
  originalSync?: boolean; // file, folder
  originalDesignType?: IntegrationDesignType; // file
}
